define('ocular-ember-plate/pods/components/pointer-popup-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['pointerPopupComponent'],

        intl: Ember.inject.service('intl'),

        options: null,

        languageChanged: Ember.observer('intl.locale', function () {
            var _this = this;

            if (this.get('audioPlaying')) {
                this.set('audioPlaying', false);
                Ember.run.later(function () {
                    _this.set('audioPlaying', true);
                }, 100);
            }
        }),

        popupAssets: null,
        audioAssets: null,
        audioObject: null,
        audioPlaying: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('popupAssets', []);
            this.set('options', {
                navigation: true,
                pagination: {
                    clickable: true
                }
            });

            this.checkAssets();
            this.setTabs();

            this.setTabActive(this.get('model.tabs.firstObject'), 0);
        },

        willDestroyElement: function willDestroyElement() {
            Ember.run.cancel(this.get('delayCarousel'));
            this.get('model.tabs').forEach(function (t) {
                Ember.set(t, 'active', false);
            });
        },

        setTabs: function setTabs() {
            var model = this.get('model');
            if (!model['tabs']) {
                var tabs = [{ title: model['title'], body: model['description'] }];
                Ember.set(model, 'tabs', tabs);
            }
        },


        checkAssets: function checkAssets() {
            var assets = this.get('model.media');
            this.set('audioAssets', []);
            this.set('popupAssets', assets);
        },

        carouselVisible: Ember.computed('popupAssets.[]', function () {
            return this.get('popupAssets').length >= 1;
        }),

        actions: {
            onClosePopupHandler: function onClosePopupHandler() {
                this.closePopup(this.get('model'));
            },
            onResetPopupHandler: function onResetPopupHandler() {
                Ember.$('.pointerPopupComponent--' + this.get('model.id')).attr('data-x', 0).attr('data-y', 0).css('transform', 'translate(0, 0)');
            },
            onClickAudioPlay: function onClickAudioPlay(audioFile) {
                this.set('audioObject', audioFile);
                if (!this.get('audioPlaying')) {
                    this.set('audioPlaying', true);
                } else {
                    this.set('audioPlaying', false);
                }
            },
            onAudioEnded: function onAudioEnded() {
                this.set('audioPlaying', false);
                this.set('audioObject', null);
            },
            openFullScreen: function openFullScreen(currentSlide) {
                currentSlide = currentSlide || 0;
                this.onFullscreenChange(currentSlide, this.get('popupAssets'));
            },
            onTabChange: function onTabChange() {
                this.stopAudio();
                this.stopVideo();
            },
            onTabClicked: function onTabClicked(tab, index) {
                this.setTabActive(tab, index);
            }
        },

        /* */

        setTabActive: function setTabActive(tab, index) {
            this.stopAudio();
            this.stopVideo();

            this.get('model.tabs').forEach(function (t) {
                Ember.set(t, 'active', false);
            });

            Ember.set(tab, 'active', true);
            this.set('activeTabText', this.get('model.tabs')[index].body);
        },


        /* */

        stopAudio: function stopAudio() {
            this.set('audioPlaying', false);
            this.set('audioObject', null);
        },
        stopVideo: function stopVideo() {}
    });
});