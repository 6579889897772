define('ocular-ember-plate/services/io/audioguide', ['exports', 'ocularium-frontend-core/services/io/audioguide'], function (exports, _audioguide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _audioguide.default;
    }
  });
});