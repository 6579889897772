define('ocular-ember-plate/helpers/getLabelForLanguage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getLabelForLanguage = getLabelForLanguage;
  function getLabelForLanguage(params /*, hash*/) {
    var labels = params[0];
    var labelProp = params[1];
    var activeLanguage = params[2];

    return labels[activeLanguage]['NBBBills'][labelProp];
  }

  exports.default = Ember.Helper.helper(getLabelForLanguage);
});