define('ocular-ember-plate/services/io/crossbar', ['exports', 'ocularium-frontend-core/services/io/crossbar'], function (exports, _crossbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _crossbar.default;
    }
  });
});