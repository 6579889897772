define('ocular-ember-plate/pods/components/dropdown-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['dropdownComponent'],
        prompt: null,
        selected: '',
        disabled: false,

        init: function init() {
            this._super.apply(this, arguments);
            if (!this.get('content')) {
                this.set('content', []);
            }
        },
        actions: {
            change: function change(value) {
                this.onChangeHandler(value);
            }
        }
    });
});