define('ocular-ember-plate/pods/components/button-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'button',
        classNames: ['buttonComponent'],
        init: function init() {
            this._super();
        },
        click: function click() {
            this.onClickHandler();
        }
    });
});