define('ocular-ember-plate/pods/components/media-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['mediaComponent'],

        userIdle: Ember.inject.service('user-idle'),

        isMediaStill: true,
        fullscreen: false,

        handleSlideChange: Ember.observer('slideChanged', function () {
            if (this.get('slideChanged')) {
                this.send("onClickMediaClose");
            }
        }),

        init: function init() {
            this._super.apply(this, arguments);
        },

        actions: {
            onClickMediaOpen: function onClickMediaOpen() {
                if (this.get('media.isZoomable') || String(this.get('media.mediaType')).toLowerCase() === "video" || String(this.get('media.mediaType')).toLowerCase() === "audio") {
                    this.set('isMediaStill', false);
                    this.onClickOpenMedia();
                    this.set('userIdle.ENABLED', false);
                }
            },
            onOpenFullScreen: function onOpenFullScreen(media) {

                this.set('isMediaStill', true);

                this.openFullScreenHandler(media);
            },
            onClickMediaClose: function onClickMediaClose() {
                this.set('isMediaStill', true);
                this.set('userIdle.ENABLED', true);
                this.get('userIdle').resetTimeout();
            },
            onVideoEnded: function onVideoEnded(loop) {
                if (!loop) {
                    this.set('isMediaStill', true);
                    this.set('userIdle.ENABLED', true);
                    this.get('userIdle').resetTimeout();
                }
            }
        }
    });
});