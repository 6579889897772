define('ocular-ember-plate/helpers/getYearOfDate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getLabelForLanguage = getLabelForLanguage;
  function getLabelForLanguage(params /*, hash*/) {
    return new Date(params).getFullYear();
  }

  exports.default = Ember.Helper.helper(getLabelForLanguage);
});