define('ocular-ember-plate/pods/components/card-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['cardComponent'],

        intl: Ember.inject.service('intl'),

        currentIndex: 0,
        currentText: null,
        currentTitle: null,

        languageChanged: Ember.observer('intl.locale', function () {
            return this.setCurrentContent();
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            if (this.get('data')) {
                Ember.run.later(function () {
                    _this.setCurrentContent();
                    _this.highlightCurrentButton(0);
                }, 0);
            }
            this.set('options', {
                navigation: true,
                pagination: {
                    clickable: true
                }
            });
        },

        setCurrentContent: function setCurrentContent() {
            var tabId = this.get('currentIndex');
            this.set('currentText', this.get('data').tabs[tabId].body);
            this.set('currentTitle', this.get('data').tabs[tabId].title);
        },
        highlightCurrentButton: function highlightCurrentButton(tabIndex) {
            var currentTab = document.getElementById('tab-' + tabIndex);
            var tabs = [].concat(_toConsumableArray(document.getElementsByClassName('cardComponent__body__tabs__title')));
            tabs.forEach(function (el) {
                el.classList.remove('active');
            });
            if (!currentTab.classList.contains('active')) {
                currentTab.classList.add('active');
            }
        },
        click: function click(target) {
            if (target.target.classList[0] === "digitallabels__card") {
                this.onCloseCardHandler();
            }
        },


        actions: {
            onTabClicked: function onTabClicked(index) {
                this.set('currentIndex', index);
                this.setCurrentContent();
                this.highlightCurrentButton(index);
            },
            onCloseCardClicked: function onCloseCardClicked() {
                this.onCloseCardHandler();
            },
            openFullScreen: function openFullScreen(currentSlide) {
                currentSlide = currentSlide || 0;
                this.openFullScreenCardMediaHandler(currentSlide, this.get('data.media'));
            }
        }
    });
});