define('ocular-ember-plate/pods/components/list-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['listComponent'],

        previousSelectedIndex: 0,

        init: function init() {
            this._super.apply(this, arguments);
        },

        actions: {
            onListItemClick: function onListItemClick(item, index) {
                // TODO - zonder jquery
                index = parseInt(index) + 1;

                this.$('.listComponent__listItem:nth-child(' + index + ')').addClass('listComponent__listItem--active');
                this.$('.listComponent__listItem:nth-child(' + this.get('previousSelectedIndex') + ')').removeClass('listComponent__listItem--active');

                this.set('previousSelectedIndex', index);
                this.listItemClickHandler(item);
            }
        }
    });
});