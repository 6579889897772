define('ocular-ember-plate/pods/components/login-confirmation-component/component', ['exports', 'ocularium-frontend-core/mixins/generic-service-mixin'], function (exports, _genericServiceMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_genericServiceMixin.default, {
        tagName: 'div',
        classNames: ['loginConfirmationComponent'],

        intl: Ember.inject.service('intl'),
        user: Ember.inject.service('user'),
        audioGuideService: Ember.inject.service('io/audioguide'),
        rfidService: Ember.inject.service('io/rfid'),
        isVisibleAudioGuide: Ember.computed.readOnly('audioGuideService.isLoginConfirmationVisible'),
        isVisibleRFID: Ember.computed.readOnly('rfidService.isLoginConfirmationVisible'),
        globalLabels: null,
        languageInterval: null,

        visibleChanged: Ember.observer('isVisibleRFID', 'isVisibleAudioGuide', function () {
            clearInterval(this.get('languageInterval'));
            if (this.get('isVisibleRFID') || this.get('isVisibleAudioGuide')) {
                var activeUser = this.get('user').getUser();
                var tempUser = this.get('user').getTempUser();
                if (activeUser && tempUser) {
                    this.rotateLanguage([activeUser.language, tempUser.language]);
                }
            }
        }),

        init: function init() {
            var _this2 = this;

            this._super.apply(this, arguments);
            this.getLabels(window.ocularium.deviceId, "global").then(function (result) {
                _this2.set('globalLabels', result);
                var lang = _this2.get('intl.locale');
                _this2.set('lblNewUser', result[lang]["newUser"]);
                _this2.set('lblContinue', result[lang]["allowUser"]);
                _this2.set('lblIgnore', result[lang]["ignoreUser"]);
            }).catch(function (reason) {
                throw reason;
            });
        },

        rotateLanguage: function rotateLanguage(languages) {
            clearInterval(this.get('languageInterval'));
            var labels = this.get('globalLabels');
            var index = 0;
            var _this = this;
            this.set('languageInterval', setInterval(function () {
                var langId = index++ % languages.length;
                _this.set('lblNewUser', labels[languages[langId]]["newUser"]);
                _this.set('lblContinue', labels[languages[langId]]["allowUser"]);
                _this.set('lblIgnore', labels[languages[langId]]["ignoreUser"]);
            }, 3000));
        },


        actions: {
            onConfirmClicked: function onConfirmClicked() {
                if (this.get('type') === "rfid") {
                    this.get('rfidService').loginUser();
                }
                if (this.get('type') === "audioguide") {
                    this.get('audioGuideService').loginUser();
                }
            },
            onIgnoreClicked: function onIgnoreClicked() {
                if (this.get('type') === "audioguide") {
                    this.set('audioGuideService.isLoginConfirmationVisible', false);
                }
                if (this.get('type') === "rfid") {
                    this.set('rfidService.isLoginConfirmationVisible', false);
                }
            }
        }
    });
});