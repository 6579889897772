define('ocular-ember-plate/pods/components/language-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['languageComponent'],
        classNameBindings: ['collapsed:collapsed'],
        collapseEnabled: false,
        language: Ember.computed.readOnly('intl.locale'),

        singleCharacter: false,

        settings: Ember.inject.service('settings'),
        intl: Ember.inject.service('intl'),

        init: function init() {
            this._super.apply(this, arguments);
            this.getApplicationLanguages();
            if (!this.get('collapseEnabled')) {
                this.set('collapsed', false);
            }
        },
        getApplicationLanguages: function getApplicationLanguages() {
            this.set('languages', this.createLanguageArray(this.get('settings').languageSettings));
        },
        createLanguageArray: function createLanguageArray(langArray) {
            var arr = [];
            langArray.forEach(function (lang) {
                var obj = {};
                obj.language = lang;
                obj.single = lang.substring(0, 1);

                arr.push(obj);
            });

            return arr;
        },


        actions: {
            onLanguageChanged: function onLanguageChanged(lang) {
                if (this.get('collapsed') && this.get('intl').get('locale')[0] === lang) {
                    this.set('collapsed', false);
                } else {
                    if (this.get('collapseEnabled')) {
                        this.set('collapsed', true);
                    }
                    this.get('intl').setLocale(lang);
                }
            }
        }

    });
});