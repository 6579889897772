define('ocular-ember-plate/services/io/rfid', ['exports', 'ocularium-frontend-core/services/io/rfid'], function (exports, _rfid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _rfid.default;
    }
  });
});