define('ocular-ember-plate/pods/components/pointer-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['pointerComponent'],

        init: function init() {
            this._super();
        },

        click: function click() {
            this.onClickHandler();
        }
    });
});