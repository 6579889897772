define('ocular-ember-plate/pods/components/pages/mediabrowser-page/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['page page--mediabrowser'],

        store: Ember.inject.service('store'),
        intl: Ember.inject.service('intl'),
        userIdleService: Ember.inject.service('user-idle'),

        mediaActive: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.setupMediaBrowser();
            this.set('options', {
                navigation: true
            });
            this.checkUserIdle();
        },

        willDestroyElement: function willDestroyElement() {
            Ember.removeListener(this.get('userIdleService'), 'idleChanged', this, 'onIdle');
            this.set('userIdleService.ENABLED', true);
            this.get('userIdleService').resetTimeout();
        },
        setInternalUserIdle: function setInternalUserIdle() {
            var userIdleService = this.get('userIdleService');
            userIdleService.ENABLED = false;
            Ember.removeListener(userIdleService, 'idleChanged', this, 'onIdle');
            Ember.addListener(userIdleService, 'idleChanged', this, 'onIdle');

            Ember.run.later(function () {
                userIdleService.ENABLED = true;
            }, 10);
        },


        onIdle: function onIdle(isIdle) {
            var userIdleService = this.get('userIdleService');
            if (isIdle && userIdleService.ENABLED === true) {
                if (!this.isDestroyed || !this.isDestroying) {
                    this.set('mediaActive', false);
                    this.setupMediaBrowser();
                }
            }
        },

        checkUserIdle: function checkUserIdle() {
            if (!this.get('userIdleService.ACTIVE_SCREENSAVER_ID')) {
                this.setInternalUserIdle();
            }
        },
        setupMediaBrowser: function setupMediaBrowser() {
            this.set('alertModel', { title: this.get('model.title'), body: this.get('model.description') });
            this.set('isIntroVisible', this.get('model.hasIntroduction'));
            var mediaObjects = [];
            this.get('model.media').forEach(function (media) {
                Ember.set(media, 'visited', false);
                mediaObjects.push(media.media);
            });
            this.set('mediaItems', mediaObjects);
        },
        introClicked: function introClicked() {
            if (this.get('model.media').length === 1) {
                this.set('activeMedia', this.get('model.media')[0]);
                this.set('mediaActive', true);
            }
            this.set('isIntroVisible', false);
        },


        slideChanged: Ember.observer('currentSlide', function () {
            Ember.set(this.get('model.media').objectAt(this.get('currentSlide')), 'visited', true);
        }),

        actions: {
            onMediaCloseHandler: function onMediaCloseHandler() {
                this.set('userIdleService.ENABLED', true);
                this.get('userIdleService').resetTimeout();

                this.set('mediaActive', false);
            },
            onIntroClickHandler: function onIntroClickHandler() {
                this.introClicked();
            },
            onMediaOpenHandler: function onMediaOpenHandler(media, index) {

                if (!media) {
                    return;
                }

                Ember.set(this.get('model.media').objectAt(index), 'visited', true);

                this.set('currentSlide', index);
                this.set('activeMedia', media);
                this.set('mediaActive', true);
            }
        }
    });
});