define('ocular-ember-plate/services/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        crossbarSettings: null,
        languageSettings: null,
        projectSettings: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.setProjectSettings();
            this.setCrossbarSettings();
            this.setLanguageSettings();
        },
        setProjectSettings: function setProjectSettings() {
            var project = {};
            project.projectName = 'demo';
            project.deviceRole = "input";

            this.set('projectSettings', project);
        },
        setCrossbarSettings: function setCrossbarSettings() {
            var crossbar = {};
            crossbar.enabled = true;
            crossbar.host = "wss://dev.ocular.be:8080/ws";
            crossbar.realm = 'julessap';
            crossbar.user = 'julesap';
            crossbar.key = 'mgiTkeHLmil36zZNV8dKTXff4S5714h5';
            this.set('crossbarSettings', crossbar);
        },
        setLanguageSettings: function setLanguageSettings() {
            var activeLanguages = ['nl', 'fr', 'de', 'en'];
            this.set('languageSettings', activeLanguages);
        }
    });
});