define('ocular-ember-plate/models/application-settings', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        applicationId: _emberData.default.attr(),
        assetBundleModuleId: _emberData.default.attr(),
        bottomCenter: _emberData.default.attr(),
        bottomLeft: _emberData.default.attr(),
        bottomRight: _emberData.default.attr(),
        countdown: _emberData.default.attr(),
        logo: _emberData.default.attr(),
        moduleId: _emberData.default.attr(),
        name: _emberData.default.attr(),
        navigationId: _emberData.default.attr(),
        navigationType: _emberData.default.attr(),
        screensaverId: _emberData.default.attr(),
        timeout: _emberData.default.attr(),
        timeoutPopup: _emberData.default.attr(),
        title: _emberData.default.attr(),
        topCenter: _emberData.default.attr(),
        topLeft: _emberData.default.attr(),
        topRight: _emberData.default.attr()
    });
});