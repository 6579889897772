define('ocular-ember-plate/pods/components/pages/interactive-map-page/component', ['exports', 'interactjs'], function (exports, _interactjs) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['page page--interactiveMap'],

        intl: Ember.inject.service('intl'),
        userIdleService: Ember.inject.service('user-idle'),

        isIntroVisible: false,
        isMapVisible: false,
        isMapInfoVisible: false,

        isFullscreenVisible: false,
        fullscreenMedia: null,

        activeMap: null,
        activePopups: null,
        activeFilters: null,

        imageWidth: null,
        imageHeight: null,

        enableResetButton: false,

        init: function init() {
            this._super();
            this.setupInteractiveMap();
            this.checkUserIdle();
            this.set('options', {
                navigation: true
            });
        },
        willDestroyElement: function willDestroyElement() {
            this.set('activePopups', []);
            this.set('isIntroVisible', this.get('model.hasIntroduction'));
            this.resetFilters();
            this.resetMaps();
            this.set('imageWidth', null);
            this.set('imageHeight', null);
            this.set('stylePointsContainer', null);
        },
        setupInteractiveMap: function setupInteractiveMap() {
            this.resetActiveMap();
            this.setMapActive(this.get('model.maps.firstObject'));
            this.set('alertModel', { title: this.get('model.title'), body: this.get('model.description') });
            this.set('isIntroVisible', this.get('model.hasIntroduction'));
            this.set('activePopups', []);
            this.set('activeFilters', []);
        },
        checkUserIdle: function checkUserIdle() {
            if (!this.get('userIdleService.ACTIVE_SCREENSAVER_ID')) {
                this.setInternalUserIdle();
            }
        },
        setInternalUserIdle: function setInternalUserIdle() {
            var userIdleService = this.get('userIdleService');
            userIdleService.ENABLED = false;
            Ember.removeListener(userIdleService, 'idleChanged', this, 'onIdle');
            Ember.addListener(userIdleService, 'idleChanged', this, 'onIdle');

            Ember.run.later(function () {
                userIdleService.ENABLED = true;
            }, 10);
        },


        onIdle: function onIdle(isIdle) {
            var userIdleService = this.get('userIdleService');
            if (isIdle && userIdleService.ENABLED === true) {
                if (!this.isDestroyed || !this.isDestroying) {
                    this.set('activePopups', []);
                    this.set('isIntroVisible', this.get('model.hasIntroduction'));
                    this.set('isInfoDrawerVisible', false);
                    this.resetFilters();
                    this.resetMaps();
                }
            }
        },

        actions: {
            onIntroClickHandler: function onIntroClickHandler() {
                this.set('isIntroVisible', false);
                this.set('isMapVisible', true);
            },
            onOpenInfoDrawer: function onOpenInfoDrawer() {
                this.set('isInfoDrawerVisible', true);
            },
            onCloseInfoDrawer: function onCloseInfoDrawer() {
                this.set('isInfoDrawerVisible', false);
            },
            onResetMapHandler: function onResetMapHandler() {
                this.set('activePopups', []);
                this.get('activeTouchpoints').forEach(function (p) {
                    return Ember.set(p, 'visited', false);
                });
                this.set('enableResetButton', false);
                this.resetFilters();
            },


            /* points of interest */

            onPoiClickedHandler: function onPoiClickedHandler(point) {
                var _this = this;

                if (this.get('activePopups').includes(point)) {
                    return;
                }

                this.get('activePopups').pushObject(point);
                Ember.set(point, 'visited', true);

                this.set('enableResetButton', true);

                Ember.run.later(function () {
                    var popup = Ember.$('.pointerPopupComponent--' + point.id);
                    var popupHeight = parseInt(popup.css('height').replace("px", ""));
                    var popupWidth = parseInt(popup.css('width').replace("px", ""));

                    var restrictionZone = Ember.$('.map__wrapper');
                    var windowWidth = parseInt(restrictionZone.css('width'));
                    var windowHeight = parseInt(restrictionZone.css('height'));

                    var diffX = (windowWidth - _this.get('imageWidth')) / 2;
                    var diffY = (windowHeight - _this.get('imageHeight')) / 2;

                    var originalY = point['position']['y'];
                    var originalX = point['position']['x'];

                    var offsetY = point['position']['y'] + diffY;
                    var offsetX = point['position']['x'] + diffX;

                    if (originalY + popupHeight >= windowHeight) {
                        offsetY = originalY + (windowHeight - (originalY + popupHeight));
                    }

                    if (originalX + popupWidth >= windowWidth) {
                        offsetX = originalX + (windowWidth - (originalX + popupWidth));
                    }

                    popup.css({
                        top: offsetY,
                        left: offsetX
                    }).attr('data-x', 0).attr('data-y', 0).css('transform', 'translate(0, 0)').css('z-index', 51);

                    if (point['draggable']) {
                        _this.enableDragging(point.id);
                    }
                }, 0);
            },
            onClosePopupHandler: function onClosePopupHandler(point) {
                this.get('activePopups').removeObject(point);
            },


            /* */

            onNavigationMapClickedHandler: function onNavigationMapClickedHandler(map) {
                this.setMapActive(map);
                this.set('enableResetButton', false);
            },
            onCategoryClickedHandler: function onCategoryClickedHandler(category) {
                this.set('activePopups', []);

                if (this.get('model.filterToggle')) {
                    // TODO moet optie worden in de back-end (true/false)
                    this.resetFilters();
                    this.set('activeFilters', []);
                }

                if (!category['active']) {
                    Ember.set(category, 'active', true);
                    this.get('activeFilters').pushObject(category);
                } else {
                    Ember.set(category, 'active', false);
                    this.get('activeFilters').removeObject(category);
                }

                this.filterTouchPoints();
            },


            /* */

            onFullscreenChange: function onFullscreenChange(index, media) {
                this.set('fullscreenMedia', media);
                this.set('currentSlide', index);
                this.set('isFullscreenVisible', true);
            },
            closeFullScreen: function closeFullScreen() {
                this.set('isFullscreenVisible', false);
                this.set('fullscreenMedia', null);
            }
        },

        setMapActive: function setMapActive(map) {
            var _this2 = this;

            this.resetFilters();
            this.resetActiveMap();
            Ember.set(map, 'active', true);
            this.set('activeMap', map);
            Ember.run.later(function () {
                return _this2.setPointsContainerSize(map);
            }, 0);
        },
        setPointsContainerSize: function setPointsContainerSize(map) {
            var _this3 = this;

            var element = document.getElementById('map__background');
            Ember.run.later(function () {
                if (_this3.isDestroyed || _this3.isDestroying) {
                    return;
                }
                _this3.set('stylePointsContainer', Ember.String.htmlSafe('width:' + element.naturalWidth + 'px;height:' + element.naturalHeight + 'px;'));
                _this3.set('imageWidth', element.naturalWidth);
                _this3.set('imageHeight', element.naturalHeight);

                _this3.generatePoints(map['touchpoints']);
            }, 15);
        },
        generatePoints: function generatePoints(points) {
            points.forEach(function (p) {
                return Ember.set(p, 'visited', false);
            });
            this.set('activeTouchpoints', points);
            this.set('activePopups', []);
        },
        enableDragging: function enableDragging(id) {
            (0, _interactjs.default)(".pointerPopupComponent--" + id).draggable({
                restrict: {
                    endOnly: true,
                    restriction: '.map__wrapper',
                    elementRect: { left: 0, right: 1, top: 0, bottom: 1 }
                },
                inertia: true,
                ignoreFrom: '.popup__content__assets, .popup__header__button',
                onmove: this.dragMoveListener,
                onend: function onend(event) {
                    event.target.classList.remove('dragging');
                }
            }).on('tap', function (event) {
                Ember.$('.pointerPopupComponent').css('z-index', '50');
                Ember.$(event.currentTarget).css('z-index', '51');
            });
        },
        dragMoveListener: function dragMoveListener(event) {

            Ember.$('.pointerPopupComponent').css('z-index', '50');

            var target = event.target,
                x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
                y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
            target.classList.add('dragging');

            // translate the element
            target.style.zIndex = 51;
            target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

            // update the posiion attributes
            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
        },
        disableDragging: function disableDragging() {
            this.get('activePopups').forEach(function (pop) {
                (0, _interactjs.default)(".pointerPopupComponent--" + pop.id).unset();
            });
        },


        /* */

        filterTouchPoints: function filterTouchPoints() {
            var _this4 = this;

            this.set('activeTouchpoints', []);

            this.get('activeFilters').forEach(function (category) {
                _this4.get('activeMap.touchpoints').forEach(function (touchpoint) {
                    if (touchpoint['categoryId'] === category['id']) {
                        _this4.get('activeTouchpoints').pushObject(touchpoint);
                    }
                });
            });

            if (this.get('activeFilters').length === 0) {
                this.set('activeTouchpoints', this.get('activeMap.touchpoints'));
            }
        },


        /* */

        resetActiveMap: function resetActiveMap() {
            this.get('model.maps').forEach(function (map) {
                Ember.set(map, 'active', false);
            });
        },
        resetMaps: function resetMaps() {
            this.setMapActive(this.get('model.maps.firstObject'));
        },
        resetFilters: function resetFilters() {
            if (this.get('activeFilters')) {
                this.get('activeFilters').forEach(function (f) {
                    Ember.set(f, 'active', false);
                });
            }
            this.set('activeFilters', []);
            this.filterTouchPoints();
        }
    });
});