define("ocular-ember-plate/pods/application/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userIdleService: Ember.inject.service("user-idle"),
    settings: Ember.inject.service("settings"),
    intl: Ember.inject.service("intl"),

    queryParams: ['language'],
    language: null,

    userIdleTimeout: 60000,
    userIdleEnabled: true,

    moduleData: null,
    moduleLabels: null,
    activeYear: 1930,

    isScreensaverVisible: true,
    selectedBill: null,

    filteredBills: null,
    filteredTags: null,
    activeTag: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.resetControllerVariables();
      //if (!(localStorage.getItem("NBB_Biljetten_Data") && localStorage.getItem("NBB_Biljetten_Labels"))) {
      this.fetchDataFromAPI();
      //} else {
      //this.set(
      //  "moduleData",
      //  JSON.parse(localStorage.getItem("NBB_Biljetten_Data"))
      //);
      //this.set(
      //  "moduleLabels",
      //  JSON.parse(localStorage.getItem("NBB_Biljetten_Labels"))
      //);
      //this.setApplicationModel();
      //
      //}
    },


    queryLang: Ember.observer('model', function () {
      if (this.get('model')) {
        this.filterBillsForActiveYear(1930);
      }
    }),

    resetControllerVariables: function resetControllerVariables() {
      this.set('filteredBills', []);
      this.set('filteredTags', []);
    },
    fetchDataFromAPI: function fetchDataFromAPI() {
      var _this = this;

      Promise.all([this.fetchModuleData(), this.fetchModuleLabels()]).then(function () {
        localStorage.setItem("NBB_Biljetten_Data", JSON.stringify(_this.get("moduleData")));
        localStorage.setItem("NBB_Biljetten_Labels", JSON.stringify(_this.get("moduleLabels")));
        _this.setApplicationModel();
        _this.filterBillsForActiveYear(1930);
      });
    },
    fetchModuleData: function fetchModuleData() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: "GET",
          url: "https://backend.ocularium.be/api/v1/modules/15/settings",
          success: function success(response) {
            _this2.set("moduleData", response);
            resolve();
          },
          error: function error() {
            reject();
          }
        });
      });
    },
    fetchModuleLabels: function fetchModuleLabels() {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: "GET",
          url: "https://backend.ocularium.be/api/v1/modules/15/labels",
          success: function success(response) {
            _this3.set("moduleLabels", response);
            resolve();
          },
          error: function error() {
            reject();
          }
        });
      });
    },
    setApplicationModel: function setApplicationModel() {
      this.initUserIdle();
      this.setUserIdleTimeout();
    },
    resetApplicationContent: function resetApplicationContent() {
      this.filterBillsForActiveYear(1930);
    },
    initUserIdle: function initUserIdle() {
      var _this4 = this;

      this.get("userIdleService").IDLE_TIMEOUT = this.get("userIdleTimeout");
      this.get("userIdleService").ENABLED = this.get("userIdleEnabled");
      this.get("userIdleService").on("idleChanged", function (isIdle) {
        if (isIdle) {
          if (_this4.get("userIdleService").ENABLED) {
            _this4.set("isScreensaverVisible", true);
            _this4.set('activeTag', null);
            _this4.set('selectedBill', null);
            _this4.set('activeYear', 1930);
          }
        }
      });
    },
    setUserIdleTimeout: function setUserIdleTimeout() {
      this.get("userIdleService").setIdle();
      this.get("userIdleService").resetTimeout();
    },
    filterBillsForActiveYear: function filterBillsForActiveYear(year) {
      var _this5 = this;

      this.get("filteredBills").clear();
      this.get("moduleData").bills.forEach(function (bill) {
        var billDateIn = new Date(bill.dateIn);
        var billDateOut = new Date(bill.dateOut);

        if (year >= billDateIn.getFullYear() && year <= billDateOut.getFullYear()) {
          _this5.get("filteredBills").push(bill);
        }
      });
      this.sortBillsByValue();
      this.getTagsForFilteredBills();
    },
    sortBillsByValue: function sortBillsByValue() {
      var _this6 = this;

      this.get("filteredBills").sort(function (a, b) {
        var billAValue = parseInt(a.value[_this6.language].split("F")[0].replace(/\s/g, "").replace(/,/g, ""));
        var billBValue = parseInt(b.value[_this6.language].split("F")[0].replace(/\s/g, "").replace(/,/g, ""));
        return billAValue - billBValue;
      });
    },
    getTagsForFilteredBills: function getTagsForFilteredBills() {
      var _this7 = this;

      var tag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var arrayWithTagIDs = [];

      this.get("filteredBills").forEach(function (filteredBill) {
        filteredBill.tags.forEach(function (tag) {
          if (arrayWithTagIDs.indexOf(tag) === -1) arrayWithTagIDs.push(tag);
        });
      });

      this.get("filteredTags").clear();
      arrayWithTagIDs.forEach(function (tagID) {
        if (tag) {
          tagID !== tag.id ? _this7.get("filteredTags").push(_this7.get("moduleData").tags.find(function (x) {
            return x.id === tagID;
          })) : null;
        } else {
          _this7.get("filteredTags").push(_this7.get("moduleData").tags.find(function (x) {
            return x.id === tagID;
          }));
        }
      });
    },
    filterBillsByTag: function filterBillsByTag(tag) {
      var filteredBillsByTag = [];

      this.get('moduleData').bills.forEach(function (bill) {
        if (bill.tags.find(function (x) {
          return x === tag.id;
        })) {
          filteredBillsByTag.push(bill);
        }
      });

      this.get('filteredBills').clear();
      this.set('filteredBills', filteredBillsByTag);
      this.set('activeTag', tag);
    },


    actions: {
      onClickScreensaver: function onClickScreensaver() {
        this.set("isScreensaverVisible", false);
        this.get("userIdleService").resetTimeout();
        this.resetApplicationContent();
      },
      onUpdatedActiveYear: function onUpdatedActiveYear(year) {
        this.set('activeYear', year);
        document.getElementsByClassName('component--grid--content__billsGrid')[0] ? document.getElementsByClassName('component--grid--content__billsGrid')[0].scrollTop = 0 : null;
        document.getElementsByClassName('billsInformation__tags')[0] ? document.getElementsByClassName('billsInformation__tags')[0].scrollTop = 0 : null;
        this.set('activeTag', null);
        this.filterBillsForActiveYear(year);
      },
      onSelectedBill: function onSelectedBill(bill) {
        this.set("selectedBill", bill);
      },
      onSelectTag: function onSelectTag(tag) {
        document.getElementsByClassName('component--grid--content__billsGrid')[0] ? document.getElementsByClassName('component--grid--content__billsGrid')[0].scrollTop = 0 : null;
        document.getElementsByClassName('billsInformation__tags')[0] ? document.getElementsByClassName('billsInformation__tags')[0].scrollTop = 0 : null;
        this.set('selectedBill', null);
        this.filterBillsByTag(tag);
        this.sortBillsByValue();
        this.getTagsForFilteredBills(tag);
      },
      onClickBackFromDetail: function onClickBackFromDetail() {
        this.set('selectedBill', null);
      }
    }
  });
});