define('ocular-ember-plate/pods/components/pages/mediashow-page/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['page page--mediashow'],

        store: Ember.inject.service('store'),
        intl: Ember.inject.service('intl'),

        languages: null,
        index: 0,

        isPlayerReady: false,
        isLoop: false,
        digitalTime: false,

        timerWidth: 100,
        timerHeight: 100,
        timerLineWidth: 10,
        hasControls: true,

        init: function init() {
            this._super.apply(this, arguments);
            this.setupMediashow();
        },

        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get('intervalCycle'));
        },
        setupMediashow: function setupMediashow() {
            this.prepareLanguageRotation();
            this.set('isPlayerReady', true);
            this.set('isLoop', this.get('model.isLoop'));
        },
        prepareLanguageRotation: function prepareLanguageRotation() {
            var arr = [];
            var languages = this.get('intl.LANGUAGES');
            this.get('intl').setLocale(languages[0]["language"]);
            languages.forEach(function (languageObj, index) {
                arr[index] = languageObj['language'];
            });
            this.set('languages', arr);
            if (String(this.get('model.media.mediaType')).toLowerCase() === "Image") {
                this.set('currentLang', arr[0]);
                this.cycleLanguages();
            }
        },
        getLanguageIndex: function getLanguageIndex() {
            var index = this.get('index');
            var l = this.get('languages').length;
            if (index === l - 1) {
                return 0;
            } else {
                return this.get('index') + 1;
            }
        },
        cycleLanguages: function cycleLanguages() {
            var _this = this;

            clearInterval(this.get('intervalCycle'));
            this.set('intervalCycle', setInterval(function () {
                _this.set('index', _this.getLanguageIndex());
                _this.set('currentLang', _this.get('languages')[_this.get('index')]);
            }, 5000));
        },


        actions: {
            onVideoEndedHandler: function onVideoEndedHandler() {},
            onVideoCloseHandler: function onVideoCloseHandler() {
                var _this2 = this;

                this.set('isPlayerReady', false);
                if (this.get('isLoop')) {
                    this.set('index', this.getLanguageIndex());
                    this.get('intl').setLocale(this.get('languages')[this.get('index')]);
                    Ember.run.later(function () {
                        _this2.set('isPlayerReady', true);
                    }, 0);
                }
            }
        }
    });
});