define('ocular-ember-plate/pods/components/flip-bill-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    bill: null,

    isMobile: null,
    isTablet: null,
    isDesktop: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setBillType();
    },
    willDestroyElement: function willDestroyElement() {
      clearTimeout(this.get('flipTimeout'));
    },
    setBillType: function setBillType() {
      var screenWidth = document.documentElement.clientWidth;

      if (screenWidth < 720) {
        this.set('isMobile', true);
        this.set('isTablet', false);
        this.set('isDesktop', false);
      } else if (screenWidth >= 720 && screenWidth < 1400) {
        this.set('isMobile', false);
        this.set('isTablet', true);
        this.set('isDesktop', false);
      } else if (screenWidth >= 1400) {
        this.set('isMobile', false);
        this.set('isTablet', false);
        this.set('isDesktop', true);
      }
    },


    actions: {
      onClickFlipBill: function onClickFlipBill() {
        var _this = this;

        this.set('isFlipping', true);
        this.set('flipTimeout', setTimeout(function () {
          _this.set('isFlipping', false);
        }, 800));
        if (Ember.$('.contentContainer__bill').hasClass('flip')) {
          Ember.$('.contentContainer__bill').removeClass('flip');
        } else {
          Ember.$('.contentContainer__bill').addClass('flip');
        }
      },
      onClickFullScreen: function onClickFullScreen(type) {
        this.onOpenImageFullscreen(type);
      }
    }
  });
});