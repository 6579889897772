define('ocular-ember-plate/pods/components/fullscreen-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['fullscreenComponent'],

        hasCloseButton: true,
        isVisible: false,

        /* timer settings */
        timerWidth: 100,
        timerHeight: 100,
        timerLineWidth: 10,

        init: function init() {
            this._super.apply(this, arguments);
        },

        actions: {
            onClickClose: function onClickClose() {
                this.onFullScreenCloseHandler();
            },
            onVideoEnded: function onVideoEnded(loop) {
                if (!loop) {
                    this.onFullScreenCloseHandler();
                }
            }
        }

    });
});