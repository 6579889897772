define("ocular-ember-plate/models/modules/unity/unity-labels", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        labels: _emberData.default.attr(),

        didLoad: function didLoad() {
            console.warn("WARNING - De Unity models mogen weg indien nodig.");
        }
    });
});