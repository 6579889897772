define('ocular-ember-plate/helpers/add-leading-zeroes', ['exports', 'ocularium-frontend-core/helpers/add-leading-zeroes'], function (exports, _addLeadingZeroes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _addLeadingZeroes.default;
    }
  });
  Object.defineProperty(exports, 'addLeadingZeroes', {
    enumerable: true,
    get: function () {
      return _addLeadingZeroes.addLeadingZeroes;
    }
  });
});