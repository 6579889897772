define('ocular-ember-plate/models/modules/speedquiz/speedquiz-settings', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        parentId: _emberData.default.attr(),
        moduleName: _emberData.default.attr(),
        time: _emberData.default.attr(),
        delay: _emberData.default.attr(),
        createdAt: _emberData.default.attr(),
        updatedAt: _emberData.default.attr(),
        deletedAt: _emberData.default.attr(),
        questions: _emberData.default.attr(),
        title: _emberData.default.attr(),
        description: _emberData.default.attr(),
        hasIntroduction: _emberData.default.attr()
    });
});