define('ocular-ember-plate/pods/components/video-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['videoComponent'],

        userIdle: Ember.inject.service('user-idle'),

        isControlsVisible: false,
        isLoop: false,
        isLoaded: false,
        isStarted: false,
        hasControls: false,
        hasTimer: false,
        hasOwnCloseBtn: false,
        mediaEl: null,
        videoProgress: 0,
        digitalTime: true,
        digitalText: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            if (this.get('media')) {
                Ember.run.later(function () {
                    _this.setupVideoElement();
                }, 0);
            }
        },

        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get('percentInterval'));
            clearInterval(this.get('textInterval'));
        },
        setupVideoElement: function setupVideoElement() {
            var _this2 = this;

            var element = document.getElementById(this.elementId).getElementsByClassName('media__el')[0];
            this.set('mediaEl', element);
            var videoElement = this.get('mediaEl');
            clearInterval(this.get('percentInterval'));
            clearInterval(this.get('textInterval'));
            videoElement.onloadeddata = function () {
                if (!_this2.isDeleted) {
                    _this2.set('time', parseInt(videoElement.duration) * 1000);
                    _this2.set('isLoaded', true);
                    _this2.get('mediaEl').currentTime = 0;
                    _this2.set('videoProgress', 0);
                    _this2.set('isStarted', true);
                    _this2.set('timerText', parseInt(videoElement.duration));
                    if (_this2.get('digitalTime')) {
                        _this2.set('digitalText', _this2.parseDigital(parseInt(videoElement.duration)));
                    }
                    if (!_this2.get('isControlsVisible')) {
                        videoElement.play();
                        _this2.updateTimer();
                    }
                }
            };
        },
        updateTimer: function updateTimer() {
            var _this3 = this;

            var bool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (!this.isDestroyed && !this.isDeleted) {
                var percent = this.get('videoProgress');
                var duration = this.get('time');
                var digitalNotation = this.get('digitalTime');
                var timerText = void 0;
                if (bool) {
                    timerText = this.get('time') / 1000;
                    if (digitalNotation) {
                        this.set('digitalText', this.parseDigital(timerText));
                    }
                } else {
                    timerText = this.get('timerText');
                }

                clearInterval(this.get('percentInterval'));
                this.set('percentInterval', setInterval(function () {
                    if (percent < 100 && _this3.get('isStarted')) {
                        percent += 10000 / duration;
                        _this3.set('videoProgress', percent);
                    }
                    if (percent < 100 && !_this3.get('isStarted')) {
                        clearInterval(_this3.get('percentInterval'));
                        clearInterval(_this3.get('textInterval'));
                    }
                    if (percent >= 100) {
                        clearInterval(_this3.get('percentInterval'));
                        clearInterval(_this3.get('textInterval'));
                        _this3.set('isStarted', false);
                        _this3.get('mediaEl').pause();
                        _this3.get('mediaEl').currentTime = 0;
                        _this3.set('videoProgress', 0);
                        if (_this3.get('isLoop')) {
                            _this3.get('mediaEl').play();
                            _this3.set('isStarted', true);
                            _this3.set('timerText', duration / 1000);
                            if (digitalNotation) {
                                _this3.set('digitalText', _this3.parseDigital(duration / 1000));
                            }
                            _this3.updateTimer();
                        } else {
                            _this3.onVideoCloseHandler();
                        }
                        _this3.onVideoEndedHandler();
                    }
                }, 100));

                clearInterval(this.get('textInterval'));
                this.set('textInterval', setInterval(function () {
                    if (percent < 100 && _this3.get('isStarted')) {
                        timerText -= 1;
                        _this3.set('timerText', timerText);
                        if (digitalNotation) {
                            _this3.set('digitalText', _this3.parseDigital(timerText));
                        }
                    }
                    if (timerText === 0) {
                        clearInterval(_this3.get('textInterval'));
                    }
                }, 1000));
            }
        },
        parseDigital: function parseDigital(duration) {
            var digitSeconds = duration % 60;
            var digitMinutes = parseInt(duration / 60);
            var digitString = digitMinutes + " : ";
            if (digitSeconds < 10) {
                digitString += "0";
            }
            digitString += digitSeconds;
            return digitString;
        },
        mediaClickHandler: function mediaClickHandler() {
            if (this.get('hasControls')) {
                this.set('isControlsVisible', true);
                //timer
                this.set('isStarted', false);
                //
                this.get('mediaEl').pause();
                this.set('userIdle.ENABLED', true);
                this.get('userIdle').resetTimeout();
            }
        },


        actions: {
            onClickMedia: function onClickMedia() {
                this.mediaClickHandler();
            },
            onClickReload: function onClickReload() {
                this.set('userIdle.ENABLED', false);
                this.set('isControlsVisible', false);
                //timer
                this.set('videoProgress', 0);
                //
                this.get('mediaEl').currentTime = 0;
                this.get('mediaEl').play();
                this.set('isStarted', true);
                this.updateTimer();
            },
            onClickPlay: function onClickPlay() {
                this.set('isControlsVisible', false);
                //timer
                this.set('isStarted', true);
                this.updateTimer(false);
                //
                this.get('mediaEl').play();
                this.set('userIdle.ENABLED', false);
            },
            onClickClose: function onClickClose() {
                this.onVideoCloseHandler();
            }
        }
    });
});