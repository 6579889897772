define('ocular-ember-plate/pods/components/pages/lobbyexperience-page/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ["page", "page--lobbyexperience"],

        intl: Ember.inject.service(),
        currentLang: 0,

        init: function init() {
            this._super.apply(this, arguments);
        },
        didInsertElement: function didInsertElement() {
            this.set('langTime', this.get('model.languageRotationTime'));
            this.set('spotlightTime', this.get('model.spotlightRotationTime'));
            this.set('header', this.get('model.lobbyHeader'));
            this.set('footer', this.get('model.lobbyFooter'));
            this.set('types', this.get('model.lobbyEventTypes'));
            var events = this.addTypes(this.get('model.lobbyEvents'), this.get('types'));
            this.set('events', events);

            var spotLights = this.addEvents(this.get('model.lobbySpotlights'), this.get('events'));
            this.set('spotlights', spotLights);

            this.startLanguageRotation();
        },
        addTypes: function addTypes(events, types) {
            if (events && types) {
                events.forEach(function (e) {
                    var type = types.find(function (x) {
                        return x.id === e.lobbyEventTypeId;
                    });
                    Ember.set(e, 'type', type ? type : null);
                });
            }
            return events;
        },
        addEvents: function addEvents(spotlights, events) {
            if (spotlights && events) {
                spotlights.forEach(function (s) {
                    var ev = events.find(function (e) {
                        return e.id === s.lobby_event_id;
                    });
                    Ember.set(s, 'event', ev ? ev : null);
                });
            }
            return spotlights;
        },


        contentRotationTime: Ember.computed('intl.LANGUAGES.[]', 'langTime', function () {
            return this.intl.LANGUAGES.length * this.get('langTime');
        }),

        startLanguageRotation: function startLanguageRotation() {
            var _this = this;

            var rot = this.get('langTime');
            var langs = this.intl.LANGUAGES;
            var max = langs.length - 1;
            this.set('languageInterval', setInterval(function () {
                var currentLang = _this.get('currentLang');
                var newLang = currentLang < max ? currentLang + 1 : 0;
                _this.intl.setLocale(langs[newLang].language);
                _this.set('currentLang', newLang);
            }, rot));
        }
    });
});