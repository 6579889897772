define('ocular-ember-plate/helpers/format-date-mysql', ['exports', 'ocularium-frontend-core/helpers/format-date-mysql'], function (exports, _formatDateMysql) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatDateMysql.default;
    }
  });
  Object.defineProperty(exports, 'formatDateMysql', {
    enumerable: true,
    get: function () {
      return _formatDateMysql.formatDateMysql;
    }
  });
});