define('ocular-ember-plate/services/user-idle', ['exports', 'ember-user-activity/services/user-idle'], function (exports, _userIdle) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _userIdle.default.extend({
        IDLE_TIMEOUT: 100,
        ENABLED: false

    });
});