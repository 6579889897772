define('ocular-ember-plate/helpers/d', ['exports', 'ocularium-frontend-core/helpers/d'], function (exports, _d) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _d.default;
    }
  });
});