define('ocular-ember-plate/pods/components/screensaver-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    init: function init() {
      this._super.apply(this, arguments);
    },


    title: Ember.computed('title', function () {
      var titleObj = {};
      for (var prop in this.get('moduleTitle')) {
        titleObj[prop] = this.get('moduleTitle')[prop].split('-')[0];
      }
      return titleObj;
    }),

    subtitle: Ember.computed('subTitle', function () {
      var subTitleObject = {};
      for (var prop in this.get('moduleTitle')) {
        subTitleObject[prop] = this.get('moduleTitle')[prop].split('-')[1];
      }
      return subTitleObject;
    }),

    activeLogoPath: Ember.computed('activeLogoPath', function () {
      var activeLanguage = this.get('intl').get('locale')[0];
      return '/assets/images/logo-nbb-' + activeLanguage + '.svg';
    }),

    actions: {
      onClickScreensaver: function onClickScreensaver() {
        this.onScreensaverClicked();
      }
    }
  });
});