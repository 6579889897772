define('ocular-ember-plate/pods/components/carousel-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['carouselComponent'],

        options: null,
        slideChanged: false,

        init: function init() {
            this._super.apply(this, arguments);
        },
        actions: {
            onClickOpenMediaHandler: function onClickOpenMediaHandler() {
                this.set('slideChanged', false);
            },
            onSlideChanged: function onSlideChanged() {
                this.set('slideChanged', true);
            },
            openFullScreen: function openFullScreen() {
                this.openFullScreenCarouselMediaHandler(this.get('currentSlide'));
            }
        }
    });
});