define('ocular-ember-plate/pods/components/pages/nav-tiles-page/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        router: Ember.inject.service(),
        breadcrumbsService: Ember.inject.service('navigation/breadcrumbs'),

        tagName: 'div',
        classNames: ['page page--nav page--nav--tiles'],
        classNameBindings: ['navigationIdClass'],

        navigationIdClass: Ember.computed('navigationId', function () {
            return "page--nav--" + this.get('navigationId');
        }),

        init: function init() {
            this._super();
        },

        actions: {
            onClickHandler: function onClickHandler(item) {
                this.get('breadcrumbsService').addBreadcrumb({
                    url: item['url'],
                    navId: item['navId'],
                    label: item['label']
                });
                this.get('router').transitionTo(item.url, item.navId);
            }
        }
    });
});