define('ocular-ember-plate/pods/components/menu-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        breadcrumbsService: Ember.inject.service('navigation/breadcrumbs'),

        tagName: 'div',
        classNames: ['menuComponent'],
        classNameBindings: ['isBreadcrumb:menuComponent--breadcrumb'],

        isBreadcrumb: false,

        init: function init() {
            this._super();
            this.set('isBreadcrumb', this.get('menuType') === 'Breadcrumb');
        },

        actions: {
            clickBack: function clickBack() {
                this.onClickBackHandler();
            },
            clickHome: function clickHome() {
                this.onClickHomeHandler();
            },
            onBreadcrumbClickHandler: function onBreadcrumbClickHandler(breadcrumb) {
                this.get('breadcrumbsService').navigateByBreadcrumb(breadcrumb);
            }
        }
    });
});