define('ocular-ember-plate/pods/components/keyboard-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({

        intl: Ember.inject.service('intl'),
        tagName: 'div',
        classNames: ['keyboardComponent'],

        classNameBindings: ['hideNumbers'],
        mailDomain: '.com',
        active: true,

        hideNumbers: false,
        specialActive: false,
        capsActive: false,
        shiftActive: false,
        keyboardType: null,
        hasEnter: false,
        hasSpecial: true,
        hasSubmit: true,

        enterAction: false,

        extraChars: null,
        replaceChars: null,

        clickAllowedClass: null,

        feedbackBefore: false,

        input: '',
        property: null,
        target: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('boundOnClick', this.get('onBodyClick').bind(this));

            this.set('numbers', ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']);
            this.set('azerty', ['a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'w', 'x', 'c', 'v', 'b', 'n', ',', '?', '.', '@']);
            this.set('qwerty', ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '@', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '?', '.']);
            this.set('special', ['@', '#', '€', '_', '&', '-', '+', '(', ')', '/', '*', '"', '\'', ':', ';', '!', '?', '.', '=', '{', '}', '\\', '%', '[', ']', '|']);

            this.set('specialfr', ['â', 'à', 'ê', 'é', 'è', 'ë', 'î', 'ï', 'ô', 'û', 'ù', 'ç']);
            this.set('specialde', ['ä', 'ö', 'ü', 'ß']);
            this.set('specialnl', ['é', 'ë', 'ï', 'ó', 'ö', 'ü']);
            this.set('specialen', []);
            this.set('speciales', ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ', '¿', '¡']);
            this.configureKeyboard();
        },

        onBodyClick: function onBodyClick(e) {
            var classes = e.target.classList;
            if (classes["value"].indexOf('keyboardComponent') <= -1 && e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'textarea' && classes["value"].indexOf('languageComponent') <= -1) {
                if (!this.get('clickAllowedClass') || classes["value"].indexOf(this.get('clickAllowedClass')) <= -1) {
                    this.send('onClose');
                }
            }
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var body = document.querySelector('body');
            body.addEventListener('click', this.get('boundOnClick'), false);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            var body = document.querySelector('body');
            body.removeEventListener('click', this.get('boundOnClick'), false);
        },
        configureKeyboard: function configureKeyboard() {
            if (this.get('intl.locale')[0] === 'nl' || this.get('intl.locale')[0] === 'fr') {
                this.set('activeCharacters', this.get('azerty'));
            } else {
                this.set('activeCharacters', this.get('qwerty'));
            }
            if (this.get('special' + this.get('intl.locale'))) {
                this.set('activeSpecials', [].concat(_toConsumableArray(this.get('special' + this.get('intl.locale'))), _toConsumableArray(this.get('special'))));
            } else {
                this.set('activeSpecials', this.get('special'));
            }
            if (this.get('extraChars')) {
                this.set('activeSpecials', [].concat(_toConsumableArray(this.get('activeSpecials')), _toConsumableArray(this.get('extraChars'))));
            }
            if (this.get('replaceChars')) {
                this.set('activeSpecials', this.get('replaceChars'));
            }
            this.set('isNumeric', this.get('keyboardType') === 'numeric');
            this.set('isMail', this.get('keyboardType') === 'mail');
        },

        uppercaseChanged: Ember.observer('shiftActive', 'capsActive', function () {
            this.checkUpperCase();
        }),

        keyboardTypeChanged: Ember.observer('keyboardType', function () {
            this.set('isNumeric', this.get('keyboardType') === 'numeric');
            this.set('isMail', this.get('keyboardType') === 'mail');
        }),

        languageChanged: Ember.observer('intl.locale', function () {
            this.configureKeyboard();
        }),

        checkUpperCase: function checkUpperCase() {
            var keys = this.$('.keyboardComponent__key');
            if (this.get('capsActive') || this.get('shiftActive')) {
                if (!keys.hasClass('keyboardComponent__key--uppercase')) {
                    keys.addClass('keyboardComponent__key--uppercase');
                }
            } else {
                if (keys.hasClass('keyboardComponent__key--uppercase')) {
                    keys.removeClass('keyboardComponent__key--uppercase');
                }
            }
        },


        actions: {
            onClose: function onClose() {
                this.onKeyboardCloseHandler();
                if (this.isDeleted || this.isDestroyed) {
                    return;
                }
                if (this.$('input:focus').length === 0 && this.$('textarea:focus').length === 0) {

                    this.set('shiftActive', false);
                    this.set('capsActive', false);
                    this.set('specialActive', false);
                }
            },
            onSubmit: function onSubmit() {
                this.onKeyboardSubmitHandler(this.get('target'));
            },
            onUpdate: function onUpdate(value) {
                var target = this.get('target');
                if (target) {
                    var targetInput = document.getElementById(target);
                    var selStart = targetInput.selectionStart;
                    var selEnd = targetInput.selectionEnd;

                    var textString = targetInput.value;

                    if (value === "remove") {
                        var part1 = textString.substring(0, selStart);
                        var deleted = textString.substring(0, selStart).substring(0, part1.length - 1);
                        targetInput.value = deleted + textString.substring(selEnd);
                        targetInput.focus();
                        targetInput.selectionStart = selStart - 1;
                        targetInput.selectionEnd = selStart - 1;
                    } else if (value === "clear") {
                        targetInput.value = "";
                        targetInput.focus();
                        targetInput.selectionStart = 0;
                        targetInput.selectionEnd = 0;
                    } else {
                        if (value === "enter") {
                            value = "\r\n";
                            if (this.get('enterAction')) {
                                this.onKeyboardEnterHandler();
                                return;
                            }
                        } else {
                            if (this.get('capsActive') || this.get('shiftActive')) {
                                value = value.toUpperCase();
                                this.set('shiftActive', false);
                            }
                        }
                        var valueResult = textString.substring(0, selStart) + value + textString.substring(selEnd);
                        if (targetInput.maxLength === -1 || valueResult.length <= targetInput.maxLength) {
                            targetInput.value = valueResult;
                            targetInput.focus();
                            targetInput.selectionStart = selStart + value.length;
                            targetInput.selectionEnd = selStart + value.length;
                        }
                    }
                    this.onKeyboardUpdateTargetValue(this.get('target'), targetInput.value);
                }
            },
            onToggle: function onToggle(target) {
                if (this.get(target + 'Active')) {
                    this.set(target + 'Active', false);
                } else {
                    this.set(target + 'Active', true);
                    if (target === "special") {
                        this.set('shiftActive', false);
                        this.set('capsActive', false);
                    }
                    if (target === 'caps') {
                        this.set('shiftActive', false);
                    } else if (target === 'shift') {
                        this.set('capsActive', false);
                    }
                }
            }
        }
    });
});