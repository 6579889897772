define('ocular-ember-plate/pods/components/lobby/lobby-spotlight-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentSpotlight: -1,

        init: function init() {
            this._super.apply(this, arguments);
        },


        spotlightsChanged: Ember.observer('spotlights', 'rotation', function () {
            if (this.get('rotation') && this.get('spotlights')) {
                if (!this.get('spotlightInterval')) {
                    this.loadSpotlights();
                }
            }
        }),

        loadSpotlights: function loadSpotlights() {
            var _this = this;

            this.showNextSpotlight();
            this.set('spotlightInterval', setInterval(function () {
                _this.showNextSpotlight();
            }, this.get('rotation')));
        },
        showNextSpotlight: function showNextSpotlight() {
            var _this2 = this;

            var filteredSpotlights = this.get('spotlights').filter(function (spot) {
                if (spot.event) {
                    var fullStartDate = spot.event.fromDate + "T" + spot.event.fromTime + "Z";
                    var fullEndDate = spot.event.tillDate + "T" + spot.event.tillTime + "Z";
                    var startDate = new Date(fullStartDate);
                    startDate.setMinutes(startDate.getMinutes() + 15);
                    return new Date() < startDate || new Date() < new Date(fullEndDate) && new Date() > startDate && new Date().getDate() >= startDate.getDate();
                }
                return true;
            });
            if (filteredSpotlights && filteredSpotlights.length > 0) {
                var next = filteredSpotlights.find(function (sp) {
                    return sp.position > _this2.get('currentSpotlight');
                });
                this.set('currentSpotlight', next ? next.position : filteredSpotlights.reduce(function (prev, curr) {
                    return prev.position < curr.position ? prev : curr;
                }).position);
            }
        }
    });
});