define('ocular-ember-plate/models/device', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        deviceId: _emberData.default.attr(),
        activeScenarioId: _emberData.default.attr(),
        activeThemeId: _emberData.default.attr(),
        crossbarSettings: _emberData.default.attr()
    });
});