define('ocular-ember-plate/pods/components/grid-item-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['gridItemComponent'],
        classNameBindings: ['title'],

        clickEnabled: true,

        init: function init() {
            this._super();
        },

        click: function click() {
            if (this.get('clickEnabled')) {
                this.onClickHandler();
            }
        }
    });
});