define('ocular-ember-plate/pods/components/header-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },

    title: Ember.computed('title', function () {
      var titleObj = {};
      for (var prop in this.get('moduleTitle')) {
        titleObj[prop] = this.get('moduleTitle')[prop].split('-')[0];
      }
      return titleObj;
    })

  });
});