define('ocular-ember-plate/pods/components/lobby/lobby-header-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        textActive: true,
        timeActive: false,
        weatherActive: false,

        weather: Ember.inject.service(),

        currentWeather: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.checkCurrentTime();
        },
        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get('rotationInterval'));
            clearInterval(this.get('weatherInterval'));
        },


        timeChanged: Ember.observer('rotation', function () {
            clearInterval(this.get('rotationInterval'));
            this.startRotation();
        }),

        headerChanged: Ember.observer('header.isWeatherEnabled', function () {
            clearInterval(this.get('weatherInterval'));
            if (this.get('header.isWeatherEnabled')) {
                this.fetchWeatherRotation();
            }
        }),

        checkCurrentTime: function checkCurrentTime() {
            var _this = this;

            this.set('timeInterval', setInterval(function () {
                _this.set('timestamp', new Date());
            }, 1000));
        },
        fetchWeatherRotation: function fetchWeatherRotation() {
            var _this2 = this;

            this.fetchWeather();
            this.set('weatherInterval', setInterval(function () {
                _this2.fetchWeather();
            }, 30 * 60 * 1000));
        },
        fetchWeather: function fetchWeather() {
            var _this3 = this;

            var params = this.get('header.weatherLocation').split(', ');
            this.weather.getWeather(params[1], params[0]).then(function (res) {
                _this3.set('currentWeather', res);
            });
        },
        startRotation: function startRotation() {
            var _this4 = this;

            this.set('rotationInterval', setInterval(function () {
                if (_this4.get('textActive')) {
                    _this4.set('textActive', false);
                    _this4.set('timeActive', true);
                } else if (_this4.get('timeActive') && _this4.get('header.isWeatherEnabled')) {
                    _this4.set('timeActive', false);
                    _this4.set('weatherActive', true);
                } else if (_this4.get('timeActive')) {
                    _this4.set('timeActive', false);
                    _this4.set('textActive', true);
                }
            }, this.get('rotation')));
        }
    });
});