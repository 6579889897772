define('ocular-ember-plate/pods/components/auto-carousel-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['auto-carousel-component'],
        intl: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
        },
        didInsertElement: function didInsertElement() {
            this.setupSlideShow();
        },
        willDestroyElement: function willDestroyElement() {
            this.resetCarousel();
        },
        resetCarousel: function resetCarousel() {
            clearTimeout(this.get('slideTimeout'));
            clearTimeout(this.get('playerTimeout'));
            var element = document.getElementById('player-' + this.get('slideIndex'));
            if (element) {
                element.pause();
            }
        },


        languageChanged: Ember.observer('intl.locale', function () {
            this.setupSlideShow();
        }),

        mediaChanged: Ember.observer('media', 'media.@each', 'media.length', function () {
            this.setupSlideShow();
        }),

        setupSlideShow: function setupSlideShow() {
            this.resetCarousel();
            if (Array.isArray(this.get('media'))) {
                this.set('initialized', false);
                this.set('slideIndex', 0);
                this.checkSlide();
            } else if (this.get('media')) {
                this.checkSingle();
            }
        },
        checkSlide: function checkSlide() {
            var _this = this;

            var media = this.get('media');
            var index = this.get('slideIndex');

            if (this.get('initialized')) {
                if (index === media.length - 1) {
                    index = 0;
                } else {
                    index++;
                }
                this.set('slideIndex', index);
            }

            var type = media[index].mediaType.toLowerCase();
            this.set('initialized', true);

            if (type === "image") {
                this.set('slideTimeout', setTimeout(function () {
                    _this.checkSlide();
                }, 5 * 1000));
            } else if (type === "video" || type === "audio") {
                this.set('playerTimeout', setTimeout(function () {
                    var element = document.getElementById('player-' + index);
                    if (element) {
                        element.play();
                    } else {
                        _this.set('slideTimeout', setTimeout(function () {
                            _this.checkSlide();
                        }, 5 * 1000));
                    }
                }, 300));
            }
        },
        checkSingle: function checkSingle() {
            var _this2 = this;

            var media = this.get('media');
            if (media.mediaType.toLowerCase() === "video" || media.mediaType.toLowerCase() === "audio") {
                this.set('playerTimeout', setTimeout(function () {
                    var element = document.getElementById('player-0');
                    if (element) {
                        element.play();
                    } else {
                        _this2.set('slideTimeout', setTimeout(function () {
                            _this2.checkSingle();
                        }, 5 * 1000));
                    }
                }, 300));
            }
        },


        actions: {
            onPlayerEnded: function onPlayerEnded() {
                this.checkSlide();
            }
        }

    });
});