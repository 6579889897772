define('ocular-ember-plate/services/monitoring/bugsnag', ['exports', 'bugsnag-js'], function (exports, _bugsnagJs) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        bugsnagClient: null,
        settings: Ember.inject.service('settings'),

        initialize: function initialize() {
            var _this = this;

            return new Promise(function (resolve) {
                var bugsnagClient = new _bugsnagJs.default('c308ac4a78bf2f430cfb22acf28adc69');

                Ember.Error.onerror = function (error) {
                    bugsnagClient.notify(error);
                };

                window.mimicBugsnagNotification = function () {
                    _this.sendNotification('Ocular Ember Plate error');
                    return '-- Bugsnag notification mimicked';
                };

                _this.set('bugsnagClient', bugsnagClient);
                _this.addMetaData();
                resolve(true);
            });
        },
        addMetaData: function addMetaData() {
            var bugsnagClient = this.get('bugsnagClient');
            bugsnagClient.metaData = {
                project: this.get('settings').projectSettings.projectName
            };
        },
        sendNotification: function sendNotification(error) {
            var bugsnagClient = this.get('bugsnagClient');
            bugsnagClient.notify(error);
        }
    });
});