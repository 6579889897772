define('ocular-ember-plate/pods/components/pages/screensaver-page/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['page page--screensaver'],

        router: Ember.inject.service('router'),
        store: Ember.inject.service('store'),
        intl: Ember.inject.service('intl'),
        rfidService: Ember.inject.service('io/rfid'),
        audioGuideService: Ember.inject.service('io/audioguide'),
        userIdleService: Ember.inject.service('user-idle'),
        screensaverRotation: null,
        languageButtonsVisible: true,
        languageIndex: 0,
        currentLanguage: null,

        init: function init() {
            this._super.apply(this, arguments);
            clearInterval(this.get('delayCycle'));
            this.set('screensaverTime', this.get('model.rotationTime') || 5000);

            this.setupScreensaver();

            var transitionPath = this.get('model.redirectPath') || "index";
            this.set('model.redirectPath', transitionPath);

            if (this.get('model.screensaverType') === "rfid") {
                if (!this.get('rfidService').isInitialized) {
                    this.get('rfidService').initialize(transitionPath);
                }
            }

            if (this.get('model.screensaverType') === "audioGuide") {
                if (!this.get('audioGuideService').isInitialized) {
                    this.get('audioGuideService').initialize(transitionPath);
                }
            }
        },
        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get('delayCycle'));
        },
        setupScreensaver: function setupScreensaver() {
            this.set('screensaverMediaData', this.get('model.media'));
            this.set('userIdleService.ACTIVE', true);

            if (this.get('languageButtonsVisible')) {
                this.getScenarioLanguages();
            }

            this.muteVideo();
        },
        muteVideo: function muteVideo() {
            Ember.run.later(function () {
                var player = document.getElementsByClassName('screensaver__backgroundVideo')[0];
                if (player) {
                    player.oncanplaythrough = function () {
                        player.muted = true;
                    };
                }
            }, 0);
        },
        getScenarioLanguages: function getScenarioLanguages() {
            this.set('languages', this.createLanguageArray(this.get('intl.LANGUAGES')));

            if (this.get('languages').length > 1) {
                this.setScreensaverRotation();
            } else {
                this.set('languageButtonsVisible', false);
            }
        },
        createLanguageArray: function createLanguageArray(languages) {
            var arr = [];

            languages.sort(function (a, b) {
                return a.position > b.position ? 1 : b.position > a.position ? -1 : 0;
            });

            languages.forEach(function (lang) {
                arr.push(lang.language);
            });

            this.set('locales', arr);
            return arr;
        },
        setScreensaverRotation: function setScreensaverRotation() {
            var _this = this;

            clearInterval(this.get('delayCycle'));
            var locales = this.get('locales');

            this.set('delayCycle', setInterval(function () {
                var currentLocale = _this.get('intl').get('locale')[0];
                var currentIndex = locales.indexOf(currentLocale);
                if (currentIndex < locales.length - 1) {
                    currentIndex++;
                } else {
                    currentIndex = 0;
                }
                _this.get('intl').setLocale(locales.get(currentIndex));
            }, this.get('screensaverTime')));
        },
        click: function click() {
            if (!this.get('languageButtonsVisible')) {
                clearInterval(this.get('delayCycle'));
                this.get('router').transitionTo(this.get('model.redirectPath'));
            }
        },


        actions: {
            onLanguageClicked: function onLanguageClicked() {
                if (event.target.classList.contains('languageComponent__button')) {
                    clearInterval(this.get('delayCycle'));
                    this.set('userIdleService.ACTIVE', false);
                    this.get('router').transitionTo(this.get('model.redirectPath'));
                }
            },
            onScreensaverClicked: function onScreensaverClicked() {
                this.set('userIdleService.ACTIVE', false);
                this.get('router').transitionTo(this.get('model.redirectPath'));
            }
        }

    });
});