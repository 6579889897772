define('ocular-ember-plate/pods/components/timeline-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    years: null,
    activeTag: null,
    isYearClicked: false,
    scrollListenerTimer: null,
    isYearSnapped: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.initTimelineComponent();

      window.addEventListener('resize', function () {
        _this.initStartEndPadding();
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      window.removeEventListener('resize', function () {
        _this2.initStartEndPadding();
      });
    },
    didRender: function didRender() {
      var _this3 = this;

      this.initMouseActionsListeners();
      Ember.run.later(function () {
        _this3.set('isYearClicked', false);
        _this3.scrollToActiveYear(0);
        _this3.initScrollListener();
      }, 80);
    },


    isTagSelected: Ember.observer('activeTag', function () {
      this.get('activeTag') !== null ? this.resetTimelineToOrigin() : null;
    }),

    initTimelineComponent: function initTimelineComponent() {
      this.initStartEndPadding();
      this.set('years', []);
      for (var i = 1851; i <= 2002; i++) {
        this.get('years').push(i);
      }
    },
    initStartEndPadding: function initStartEndPadding() {
      Ember.run.later(function () {
        var startPadding = document.getElementsByClassName('timeline__year__startPadding')[0];
        var endPadding = document.getElementsByClassName('timeline__year__endPadding')[0];

        startPadding ? startPadding.setAttribute('style', 'width:' + Ember.$('.component--timeline__timelineContainer').width() / 2 + 'px') : null;
        endPadding ? endPadding.setAttribute('style', 'width:' + Ember.$('.component--timeline__timelineContainer').width() / 2 + 'px') : null;
      }, 2);
    },
    initMouseActionsListeners: function initMouseActionsListeners() {
      var x = void 0,
          left = void 0,
          down = void 0;
      Ember.run.later(function () {
        Ember.$('.component--timeline__timelineContainer').mousedown(function (e) {
          e.preventDefault();
          down = true;
          x = e.pageX;
          left = Ember.$(this).scrollLeft();
        }).mouseup(function () {
          down = false;
        });

        Ember.$('.component--timeline__timelineContainer').mousemove(function (e) {
          if (down) {
            var newX = e.pageX;
            Ember.$(".component--timeline__timelineContainer").scrollLeft(left - newX + x);
          }
        });
      }, 10);
    },
    scrollToActiveYear: function scrollToActiveYear(animationDuration) {
      var activeYearItemOffsetLeftCenter = Ember.$('.year--' + this.get('activeYear'))[0].offsetLeft + Ember.$('.year--' + this.get('activeYear'))[0].clientWidth / 2;
      var timelineMiddle = Ember.$('.component--timeline__timelineContainer').width() / 2;
      var self = this;
      Ember.$('.component--timeline__timelineContainer').animate({
        'scrollLeft': activeYearItemOffsetLeftCenter - timelineMiddle
      }, animationDuration, function () {
        self.set('isYearClicked', false);
        self.set('isYearSnapped', true);
      });
    },
    initScrollListener: function initScrollListener() {
      var _this4 = this;

      var scrollTimeout = null;

      clearTimeout(scrollTimeout);
      Ember.$('.component--timeline__timelineContainer').scroll(function () {
        if (!_this4.get('isYearClicked')) {
          _this4.set('isYearSnapped', false);
          //Native scroll -> check when scroll ends to snap to closest year
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            if (!_this4.get('isYearSnapped')) {
              _this4.findClosestYearAndSnapToYear();
              _this4.set('isYearSnapped', true);
            }
          }, 250);
        }
      });
    },
    findClosestYearAndSnapToYear: function findClosestYearAndSnapToYear() {
      var timelineOffsetLeft = Ember.$('.component--timeline__timelineContainer')[0].scrollLeft + Ember.$('.component--timeline__timelineContainer').width() / 2;

      var closestYear = {
        year: 0,
        distance: 1000000
      };

      this.get('years').forEach(function (year) {
        var yearElement = document.getElementsByClassName('year--' + year)[0];
        var yearElementCenter = parseInt(yearElement.offsetLeft + yearElement.clientWidth / 2);
        var distanceFromCenterToCenter = Math.abs(yearElementCenter - timelineOffsetLeft);

        if (closestYear.distance > distanceFromCenterToCenter) {
          closestYear.year = year;
          closestYear.distance = distanceFromCenterToCenter;
        }
      });

      if (closestYear.distance > 0 && this.get('activeYear') !== closestYear.year) {
        this.set('isYearClicked', true);
        this.set('activeYear', closestYear.year);
        this.updateActiveYear(this.get('activeYear'));
        this.scrollToActiveYear(500);
      }
    },
    resetTimelineToOrigin: function resetTimelineToOrigin() {
      this.set('isYearClicked', false);
      this.set('activeYear', 1930);
      this.scrollToActiveYear(100);
    },


    actions: {
      onClickYear: function onClickYear(year) {
        this.set('isYearClicked', true);
        this.set('activeYear', year);
        this.updateActiveYear(this.get('activeYear'));
        this.scrollToActiveYear(500);
      }
    }
  });
});