define('ocular-ember-plate/pods/demo/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        crossbarService: Ember.inject.service('io/crossbar'),
        settings: Ember.inject.service('settings'),
        bugsnagService: Ember.inject.service('monitoring/bugsnag'),
        init: function init() {
            this._super.apply(this, arguments);
            this.resetModule();
            this.createTestVideoObject();
            this.initCrossbarListener();
        },
        resetModule: function resetModule() {
            this.set('testData', ["value1", "value2", "value3"]);
            this.set('isAlertVisible', false);
            this.set('testTitle', { nl: 'testtitel nl', fr: 'testtitel fr', en: 'testtitel en', de: 'testtitel de' });
            this.set('testBody', {
                nl: 'Alert testcontent NL',
                fr: 'Alert testcontent FR',
                en: 'Alert testcontent EN',
                de: 'alert testcontent DE'
            });
            this.set('testObject', { nl: 'testnl', fr: 'testfr', en: 'testen', de: null });
            this.set('started', false);
            this.set('percentage', 0);
            this.set('time', 0);

            this.set('isKeyboardActive', false);
            this.set('isFullScreenOpened', false);

            this.set('options', {
                navigation: true,
                pagination: {
                    clickable: true
                }
            });
            this.set('autoplay', {
                delay: 6000
            });
            this.set('pagination', {
                clickable: true
            });

            this.set('chartData', [["Chocolate", 5], ["Rhubarb compote", 2], ["Crêpe Suzette", 2], ["American blueberry", 2], ["Buttermilk", 1]]);
        },
        initCrossbarListener: function initCrossbarListener() {},
        createTestVideoObject: function createTestVideoObject() {
            var itemsArray = [];
            var video = {};
            video.path = {
                nl: 'assets/videos/dummyvid.mp4',
                fr: 'assets/videos/dummyvid.mp4',
                en: 'assets/videos/dummyvid.mp4',
                de: 'assets/videos/dummyvid.mp4'
            };
            video.mediaType = "Video";
            video.mediaStill = "assets/images/dummyThumb.jpg";
            video.isZoomable = true;
            video.isLoop = true;
            this.set('media', video);

            var audio = {};
            audio.mediaType = "Audio";
            audio.path = {
                nl: '/uploads/audio/calm.wav',
                fr: '/uploads/audio/calm.wav',
                en: '/uploads/audio/calm.wav',
                de: '/uploads/audio/calm.wav'
            };
            audio.isZoomable = true;
            audio.mediaStill = "assets/images/dummyThumb.jpg";
            this.set('media2', audio);

            var image = {};
            image.mediaType = "Image";
            image.mediaStill = "assets/images/dummyThumb.jpg";
            image.path = {
                nl: "assets/images/dummyThumb.jpg",
                fr: "assets/images/dummyThumb.jpg",
                en: "assets/images/dummyThumb.jpg",
                de: "assets/images/dummyThumb.jpg"
            };
            this.set('media3', image);
            itemsArray.pushObject(video);
            itemsArray.pushObject(audio);
            itemsArray.pushObject(image);
            this.set('itemsArray', itemsArray);

            this.set('data', {
                title: { nl: "TitelNL", fr: "TitelFR", de: "TitelDE", en: "TitelEn" },
                subtitle: { nl: "SubtitelNL", fr: "SubtitelFR", de: "SubtitelDE", en: "SubtitelEN" },
                medias: itemsArray,
                tabs: [{ body: { nl: "Body 0 NL", fr: "Body 0 FR", de: "Body 0 DE", en: "Body 0 EN" } }, { body: { nl: "Body 1 NL", fr: "Body 1 FR", de: "Body 1 DE", en: "Body 1 EN" } }, { body: { nl: "Body 2 NL", fr: "Body 2 FR", de: "Body 2 DE", en: "Body 2 EN" } }]
            });
        },
        updateTimer: function updateTimer() {
            var _this = this;

            var percent = this.get('percentage');
            var duration = this.get('time');
            var started = this.get('started');
            if (percent < 100 && started) {
                this.set('percentage', percent + 1);
                Ember.run.later(function () {
                    _this.updateTimer();
                }, duration / 100);
            } else if (percent === 100) {
                var video = document.getElementById('dummyvid');
                video.currentTime = 0;
                this.set('percentage', 0);
                this.set('started', false);
            }
        },


        actions: {
            onClickSendCrossbarMessage: function onClickSendCrossbarMessage() {
                // this.get('crossbarService').publishCrossbarMessage("be.ocular." + this.get('settings').crossbarSettings.projectName, {
                //     type: "command.test",
                //     params: {}
                // });
            },
            onClickBugsnagNotification: function onClickBugsnagNotification() {
                this.get('bugsnagService').sendNotification('Ocular Ember demo route error');
            },
            toggle: function toggle(value) {
                if (this.get('collapsed' + value)) {
                    this.set('collapsed' + value, false);
                } else {
                    this.set('collapsed' + value, true);
                }
            },
            btnClickButton: function btnClickButton() {
                alert('Clicked Button');
            },
            dropDownChange: function dropDownChange(value) {
                alert('dropdown value changed to: ' + value);
            },


            /*Alert Component actions*/
            btnShowAlertClick: function btnShowAlertClick() {
                this.set('isAlertVisible', true);
            },
            btnAlertClick: function btnAlertClick() {
                this.set('isAlertVisible', false);
            },


            /*Menu Component Actions*/
            onClickBackHandler: function onClickBackHandler() {},
            onClickHomeHandler: function onClickHomeHandler() {},


            /*Video Compnent actions*/
            playClick: function playClick() {
                var video = document.getElementById('dummyvid');
                this.set('time', video.duration * 1000);
                if (!this.get('started')) {
                    video.play();
                    this.set('started', true);
                    this.updateTimer();
                }
            },
            pauseClick: function pauseClick() {
                var video = document.getElementById('dummyvid');
                video.pause();
                this.set('started', false);
            },
            resetClick: function resetClick() {
                this.set('percentage', 0);
                var video = document.getElementById('dummyvid');
                video.pause();
                video.currentTime = 0;

                if (this.get('started')) {
                    video.play();
                }
            },


            /*Keyboard Component Functions*/
            onFocusInput: function onFocusInput(target) {
                var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

                this.set('isKeyboardActive', true);
                this.set('currentInput', target);
                this.set('currentInputType', type);
            },
            onKeyboardClose: function onKeyboardClose() {
                this.set('isKeyboardActive', false);
            },
            onKeyboardSubmit: function onKeyboardSubmit() {
                this.set('isKeyboardActive', false);
            },
            onKeyboardUpdate: function onKeyboardUpdate(target, value) {
                this.set(target, value);
            },


            /*Video Component actions*/
            videoCompCloseHandler: function videoCompCloseHandler() {

                this.set('collapsed8', true);
            },
            videoCompEndHandler: function videoCompEndHandler() {},


            /*Media Component actions*/
            onClickOpenMediaHandler: function onClickOpenMediaHandler() {},
            openFullScreen: function openFullScreen(media) {
                this.set('isFullScreenOpened', true);
                this.set('fullscreenMedia', media);
            },


            /*FullScreen Component actions*/
            closeFullScreen: function closeFullScreen() {
                this.set('isFullScreenOpened', false);
                this.set('fullscreenMedia', null);
            },


            /*Grid Item Actions*/
            onGridItemClicked: function onGridItemClicked() {
                alert('grid item clicked!');
            },


            /*Card component*/
            closeCard: function closeCard() {},


            /*List component*/
            listItemClick: function listItemClick(item) {
                alert('list item clicked: ' + item.mediaType);
            }
        }
    });
});