define('ocular-ember-plate/helpers/is-in-array', ['exports', 'ocularium-frontend-core/helpers/is-in-array'], function (exports, _isInArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isInArray.default;
    }
  });
  Object.defineProperty(exports, 'isInArray', {
    enumerable: true,
    get: function () {
      return _isInArray.isInArray;
    }
  });
});