define('ocular-ember-plate/pods/components/timer-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['timerComponent'],

        canvas: null,
        context: null,
        posX: null,
        posY: null,
        percentage: 0,

        init: function init() {
            this._super.apply(this, arguments);
            this.setupTimer();
        },

        setupTimer: function setupTimer() {
            var _this = this;

            var width = this.get('width') || 100;
            var height = this.get('height') || 100;
            this.set('posX', width * 0.5);
            this.set('posY', height * 0.5);

            Ember.run.later(function () {
                _this.set('canvas', _this.$('.timerComponent__canvas').get(0));
                _this.set('context', _this.$('.timerComponent__canvas').get(0).getContext('2d'));
            }, 0);
        },


        percentageChanged: Ember.observer('percentage', function () {
            var context = this.get('context');
            var percent = this.get('percentage');
            var posX = this.get('posX');
            var posY = this.get('posY');
            var width = this.get('width') || 100;
            var height = this.get('height') || 100;
            this.get('canvas').width = width;
            this.get('canvas').height = height;

            var lineWidth = this.get('lineWidth') || 10;

            var degrees = 360 / 100 * this.get('percentage');
            var widthPercentage = (width / 2 - lineWidth / 2) / width;

            context.clearRect(0, 0, width, height);
            context.beginPath();
            context.arc(posX, posY, width * widthPercentage, Math.PI / 180 * 270, Math.PI / 180 * (270 + 360));
            context.strokeStyle = this.get('strokeBg') || "rgba(0,0,0,0.4)";
            context.lineWidth = lineWidth;
            if (percent < 100 && percent > 0) {
                context.stroke();
            }
            context.lineCap = 'round';
            context.beginPath();
            context.strokeStyle = this.get('stroke') || "rgba(255,255,255,1)";
            context.lineWidth = lineWidth;
            context.arc(posX, posY, width * widthPercentage, Math.PI / 180 * 270, Math.PI / 180 * (270 + degrees));
            if (percent < 100 && percent > 0) {
                context.stroke();
            }
        })

    });
});