define('ocular-ember-plate/pods/components/idle-warning-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['idleWarningComponent'],

        userIdleService: Ember.inject.service('user-idle'),
        breadcrumbsService: Ember.inject.service('navigation/breadcrumbs'),

        timer: null,

        init: function init() {
            this._super();
            this.startCountdown();
        },

        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get('timer'));
            this.set('timer', null);
        },
        startCountdown: function startCountdown() {
            var _this = this;

            var count = this.get('time');
            this.set('timer', setInterval(function () {
                _this.set('count', count);
                if (count === 0) {
                    _this.clearTimer();
                    _this.get('breadcrumbsService').clearBreadcrumbs();
                    _this.onSleepHandler();
                }
                count--;
            }, 1000));
        },
        clearTimer: function clearTimer() {
            clearTimeout(this.get('timer'));
            this.set('timer', null);
        },


        actions: {
            wakeUpHandler: function wakeUpHandler() {
                this.clearTimer();
                this.onWakeUpHandler();
            }
        }
    });
});