define('ocular-ember-plate/helpers/show-svg', ['exports', 'ocularium-frontend-core/helpers/show-svg'], function (exports, _showSvg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _showSvg.default;
    }
  });
  Object.defineProperty(exports, 'showSvg', {
    enumerable: true,
    get: function () {
      return _showSvg.showSvg;
    }
  });
});