define('ocular-ember-plate/pods/components/bill-detail-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedTags: null,
    activeLanguage: null,
    isFullScreenVisible: false,
    fullScreenType: null,
    intl: Ember.inject.service('intl'),

    isMobile: false,
    isTablet: false,
    isDesktop: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.resetComponent();
      this.getTagsConnectedToBill();
      this.set('activeLanguage', this.get('intl').get('locale')[0]);

      this.calculateActiveScreenType();

      window.addEventListener('resize', function () {
        _this.calculateActiveScreenType();
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      clearTimeout(this.get('flipTimeout'));
      window.removeEventListener('resize', function () {
        _this2.calculateActiveScreenType();
      });
    },


    onLanguageChanged: Ember.observer('intl.locale', function () {
      this.set('activeLanguage', this.get('intl').get('locale')[0]);
    }),

    hasDesignerTags: Ember.computed('hasDesignerTags', function () {
      return this.get('connectedTags').find(function (x) {
        return x.category === 'designer';
      });
    }),

    hasPublisherTags: Ember.computed('hasPublisherTags', function () {
      return this.get('connectedTags').find(function (x) {
        return x.category === 'publisher';
      });
    }),

    hasSeriesTags: Ember.computed('hasSeriesTags', function () {
      return this.get('connectedTags').find(function (x) {
        return x.category === 'series';
      });
    }),

    hasPersonTags: Ember.computed('hasPersonTags', function () {
      return this.get('connectedTags').find(function (x) {
        return x.category === 'person';
      });
    }),

    hasFeatureTags: Ember.computed('hasFeatureTags', function () {
      return this.get('connectedTags').find(function (x) {
        return x.category === 'feature';
      });
    }),

    resetComponent: function resetComponent() {
      this.set('connectedTags', []);
    },
    calculateActiveScreenType: function calculateActiveScreenType() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      var screenWidth = document.documentElement.clientWidth;
      if (screenWidth < 720) {
        this.set('isMobile', true);
        this.set('isTablet', false);
        this.set('isDesktop', false);
      } else if (screenWidth >= 720 && screenWidth < 1400) {
        this.set('isMobile', false);
        this.set('isTablet', true);
        this.set('isDesktop', false);
      } else {
        this.set('isMobile', false);
        this.set('isTablet', false);
        this.set('isDesktop', true);
      }
    },
    getTagsConnectedToBill: function getTagsConnectedToBill() {
      var _this3 = this;

      this.get('connectedTags').clear();

      this.get('tags').forEach(function (tag) {
        if (_this3.get('activeBill').tags.find(function (x) {
          return x === tag.id;
        })) {
          _this3.get('connectedTags').push(tag);
        }
      });
    },


    actions: {
      onClickBack: function onClickBack() {
        if (this.get('isFullScreenVisible')) {
          this.set('isFullScreenVisible', false);
          this.set('fullScreenType', null);
        } else {
          this.onClickBack();
        }
      },
      onClickTag: function onClickTag(tag) {
        this.onSelectTag(tag);
      },
      onClickFlipBillFull: function onClickFlipBillFull() {
        var _this4 = this;

        this.set('isFlippingFull', true);
        this.set('flipTimeout', setTimeout(function () {
          _this4.set('isFlippingFull', false);
        }, 800));
        if (document.getElementById('fullscreen').classList.contains('flip')) {
          document.getElementById('fullscreen').classList.remove('flip');
        } else {
          document.getElementById('fullscreen').classList.add('flip');
        }
      },
      onOpenImageFullscreen: function onOpenImageFullscreen(type) {
        this.set('isFullScreenVisible', true);
        this.set('fullScreenType', type);
      }
    }
  });
});