define('ocular-ember-plate/pods/application/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    crossbarService: Ember.inject.service('io/crossbar'),
    webSocketService: Ember.inject.service('io/websockets'),
    bugsnagService: Ember.inject.service('monitoring/bugsnag'),
    settings: Ember.inject.service('settings'),

    intl: Ember.inject.service('intl'),
    moduleSettings: null,

    beforeModel: function beforeModel() {
      /*Setup Bugsnag*/
      this.get('bugsnagService').initialize();
      /*Set Default Language*/
      //this.get('intl').setLocale(this.get('settings').languageSettings[0]);
    },
    model: function model(params) {
      if (Ember.isPresent(params.language) && this.settings.languageSettings.indexOf(params.language) > -1) {
        this.transitionTo({ queryParams: { language: params.language } });
        this.intl.setLocale(params.language);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var language = this.get('controller.language');
      if (Ember.isEmpty(language) || this.get('settings').languageSettings.indexOf(language) < 0) {
        this.transitionTo({ queryParams: { language: "nl" } });
        this.intl.setLocale("nl");
      }
    }
  });
});