define('ocular-ember-plate/helpers/format-coords-degrees', ['exports', 'ocularium-frontend-core/helpers/format-coords-degrees'], function (exports, _formatCoordsDegrees) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatCoordsDegrees.default;
    }
  });
  Object.defineProperty(exports, 'formatCoordsDegrees', {
    enumerable: true,
    get: function () {
      return _formatCoordsDegrees.formatCoordsDegrees;
    }
  });
});