define('ocular-ember-plate/pods/components/pages/digitallabels-page/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['page page--digitalLabels digitallabels'],

        intl: Ember.inject.service('intl'),
        userIdleService: Ember.inject.service('user-idle'),

        isIntroVisible: false,
        introTitle: null,
        introDescription: null,
        isFullscreenVisible: false,
        cards: null,
        MARGIN: 0.03,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('isIntroVisible', this.get('model.hasIntroduction'));
            this.set('hasFlipAnimation', this.get('model.hasFlipAnimation'));
            this.set('isFullscreenVisible', false);
            this.set('introData', { title: this.get('model.title'), body: this.get('model.description') });
            this.set('cards', this.get('model.cards'));
            this.checkUserIdle();
            this.set('options', {
                navigation: true
            });
        },
        checkUserIdle: function checkUserIdle() {
            if (!this.get('userIdleService.ACTIVE_SCREENSAVER_ID')) {
                this.setInternalUserIdle();
            }
        },
        setInternalUserIdle: function setInternalUserIdle() {
            var userIdleService = this.get('userIdleService');
            userIdleService.ENABLED = false;
            Ember.removeListener(userIdleService, 'idleChanged', this, 'onIdle');
            Ember.addListener(userIdleService, 'idleChanged', this, 'onIdle');

            Ember.run.later(function () {
                userIdleService.ENABLED = true;
            }, 10);
        },


        onIdle: function onIdle(isIdle) {
            var userIdleService = this.get('userIdleService');
            if (isIdle && userIdleService.ENABLED === true) {
                if (!this.isDestroyed || !this.isDestroying) {
                    this.set('activeCard', null);
                    this.resetCardsActive();
                    this.resetCardsVisited();
                    this.set('isIntroVisible', this.get('model.hasIntroduction'));
                }
            }
        },

        didInsertElement: function didInsertElement() {
            var cards = this.get('cards');
            this.resetCardsActive();
            this.resetCardsVisited();
            this.setDimensions(cards);
        },
        willDestroyElement: function willDestroyElement() {
            Ember.removeListener(this.get('userIdleService'), 'idleChanged', this, 'onIdle');
            this.resetCardsActive();
            this.resetCardsVisited();
        },
        resetCardsActive: function resetCardsActive() {
            var c = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            var cards = this.get('cards');
            if (cards) {
                cards.forEach(function (card) {
                    if (c && card.position !== c.position) {
                        Ember.set(card, 'active', false);
                    } else if (!c) {
                        Ember.set(card, 'active', false);
                    }
                });
            }
        },
        resetCardsVisited: function resetCardsVisited() {
            var _this = this;

            var cards = this.get('cards');
            if (cards) {
                //TODO real data structuur met dynamische tabs
                cards.forEach(function (card) {
                    Ember.set(card, 'visited', false);
                    if (!card.tabs) {
                        var tabs = [];

                        if (_this.checkTabContent(card.text)) {
                            var text = { title: "explore", body: card.text };
                            tabs.push(text);
                        }

                        if (_this.checkTabContent(card.factsAndFigures)) {
                            var facts = { title: "factsAndFigures", body: card.factsAndFigures };
                            tabs.push(facts);
                        }

                        Ember.set(card, 'tabs', tabs);
                    }
                });
            }
        },
        checkTabContent: function checkTabContent(content) {
            var ok = false;
            Object.keys(content).forEach(function (key) {
                if (content[key]) {
                    ok = true;
                }
            });

            return ok;
        },
        setDimensions: function setDimensions(cards) {
            var cardGrid = document.getElementById('cardGrid');
            var height = cardGrid.clientHeight;
            var width = cardGrid.clientWidth;
            var margin = this.MARGIN * width;
            var square = height / 2;
            var double = 2 * square + margin;
            var lastItem = "portrait";
            var currentY = 0;
            var currentX = margin;
            var missingSquares = 0;
            var positioned = [];
            var last2 = false;
            var last = false;

            while (positioned.length < cards.length) {
                if (positioned.length >= 2) {
                    last2 = cards.find(function (crd) {
                        return crd.position === positioned[positioned.length - 2];
                    });
                }

                if (positioned.length >= 1) {
                    last = cards.find(function (crd) {
                        return crd.position === positioned[positioned.length - 1];
                    });
                }

                if (last && last2) {
                    if (last.size === 'landscape' && last2.size === 'landscape') {

                        currentX += square;
                        currentX += margin;

                        if (last.x === last2.x) {
                            currentX += square;
                            currentX += margin;
                        }
                    }
                    if (last.size === 'landscape' && last2.size === 'square') {
                        if (last.y !== last2.y) {
                            currentX += square;
                            currentX += margin;
                        }
                    }

                    if (last.size === 'square' && last2.size === 'landscape') {
                        if (last.x !== last2.x) {
                            currentX += square;
                            currentX += margin;
                            currentY = 0;
                            lastItem = 'square';
                        }
                    }

                    if (last.size === 'square' && last2.size === 'square') {
                        if (last.x === last2.x) {
                            currentX += square;
                            currentX += margin;
                        }
                    }
                }

                var c = void 0;
                if (lastItem === "portrait") {
                    c = cards.find(function (card) {
                        return positioned.indexOf(card.position) < 0;
                    });
                } else if (lastItem === "square") {
                    if (currentY !== 0) {
                        c = cards.find(function (card) {
                            return positioned.indexOf(card.position) < 0 && card.size !== "portrait";
                        });
                    } else {
                        c = cards.find(function (card) {
                            return positioned.indexOf(card.position) < 0;
                        });
                    }
                } else if (lastItem === "landscape") {
                    c = cards.find(function (card) {
                        return positioned.indexOf(card.position) < 0 && card.size !== "portrait";
                    });
                }
                if (c) {
                    var card = document.getElementById('card--' + c.position);
                    if (c.size === "portrait") {
                        card.style.height = double + 'px';
                    } else {
                        card.style.height = square + 'px';
                    }
                    if (c.size === "landscape") {
                        card.style.width = double + 'px';
                    } else {
                        card.style.width = square + 'px';
                    }
                    card.style.left = currentX + 'px';
                    card.style.top = currentY + 'px';
                    Ember.set(c, 'x', currentX);
                    Ember.set(c, 'y', currentY);
                    if (c.size === "portrait") {
                        currentX += square;
                        currentX += margin;
                    }
                    if (c.size === "portrait" || currentY !== 0) {
                        currentY = 0;
                    } else {
                        currentY = square + margin;
                    }
                    lastItem = c.size;
                    positioned.push(c.position);
                } else {
                    lastItem = "square";
                    missingSquares++;
                    if (currentY !== 0) {
                        currentX += square;
                        currentX += margin;
                        currentY = 0;
                    } else {
                        currentY = square + margin;
                    }
                }
            }
            var containerWidth = void 0;
            if (last.size === "landscape" || lastItem === "landscape") {
                containerWidth = currentX + double + margin;
            } else {
                containerWidth = currentX;
            }
            if (missingSquares > 0) {
                console.warn('-- squares missing to fill grid --');
            }
            cardGrid.style.width = containerWidth + 'px';
        },
        openCardHandler: function openCardHandler(card) {
            this.set('activeCard', card);
            Ember.set(card, 'visited', true);
        },


        actions: {
            onBtnIntroClicked: function onBtnIntroClicked() {
                this.set('isIntroVisible', !this.get('isIntroVisible'));
            },
            onCardClicked: function onCardClicked(card) {

                if (!this.get('hasFlipAnimation')) {
                    this.openCardHandler(card);
                    return;
                }
                this.resetCardsActive(card);
                Ember.set(card, 'active', !card.active);
            },
            openCard: function openCard(card) {
                this.openCardHandler(card);
            },
            closeCard: function closeCard() {
                Ember.set(this.get('activeCard'), 'active', false);
                this.set('activeCard', null);
            },
            openFullScreen: function openFullScreen(index, media) {
                this.set('isFullscreenVisible', true);
                this.set('currentSlide', index);
                this.set('fullscreenMedia', media);
            },
            closeFullScreen: function closeFullScreen() {
                this.set('isFullscreenVisible', false);
            }
        }
    });
});