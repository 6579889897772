define('ocular-ember-plate/pods/components/lobby/lobby-list-component/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        rollArray: null,
        activeRoll: -1,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('removedEventIds', []);
        },


        eventsChanged: Ember.observer('events', function () {
            clearInterval(this.get('eventInterval'));
            this.loadEvents();
        }),

        programObserver: Ember.observer('activeEvents.length', function () {
            this.set('itemsLength', this.get('activeEvents').length);

            this.get('removedEventIds').forEach(function (id) {
                var divToDelete = document.getElementById('item-' + id);
                if (divToDelete) {
                    divToDelete.parentNode.removeChild(divToDelete);
                }
            });
        }),

        loadEvents: function loadEvents() {
            var _this = this;

            this.checkEvents();
            this.set('eventInterval', setInterval(function () {
                _this.checkEvents();
            }, 30 * 1000));
        },
        checkEvents: function checkEvents() {
            var _this2 = this;

            var eventArray = [];
            var todayArray = [];
            var activeEvents = void 0;
            this.get('events').forEach(function (event) {
                var fullStartDate = event.fromDate + "T" + event.fromTime + "Z";
                var fullEndDate = event.tillDate + "T" + event.tillTime + "Z";

                Ember.set(event, 'showEnd', event.fromDate !== event.tillDate);

                var startDate = new Date(fullStartDate);
                startDate.setMinutes(startDate.getMinutes() + 15);
                if (new Date() < startDate || new Date() < new Date(fullEndDate) && new Date() > startDate) {
                    eventArray.push(event);
                    if (startDate.getDate() <= new Date().getDate() && startDate.getMonth() <= new Date().getMonth() && startDate.getFullYear() <= new Date().getFullYear()) {
                        todayArray.push(event);
                    }
                } else {
                    _this2.get('removedEventIds').push(event.id);
                }
            });

            eventArray.sort(function (a, b) {
                return new Date(a.fromDate) - new Date(b.fromDate);
            });

            todayArray.sort(function (a, b) {
                return new Date(a.fromDate) - new Date(b.fromDate);
            });

            activeEvents = todayArray.length >= 5 ? todayArray : eventArray.slice(0, 5);

            this.set('activeEvents', activeEvents);

            var rollArrays = [];
            var i = void 0,
                chunkArray = void 0,
                chunk = 5;
            for (i = 0; i < activeEvents.length; i += chunk) {
                chunkArray = eventArray.slice(i, i + chunk);
                rollArrays.push(chunkArray);
            }

            this.set('rollArray', rollArrays);
        },


        rollArrayChanged: Ember.observer('rollArray', function () {
            if (!this.get('rollInterval')) {
                this.updateProgress();
                this.checkCurrentRoll();
                this.rotateEvents();
            }
        }),

        rotateEvents: function rotateEvents() {
            var _this3 = this;

            this.set('rollInterval', setInterval(function () {
                _this3.updateProgress();
                _this3.checkCurrentRoll();
            }, this.get('rotation')));
        },
        checkCurrentRoll: function checkCurrentRoll() {
            this.set('activeRoll', this.get('activeRoll') < this.get('rollArray').length - 1 ? this.get('activeRoll') + 1 : 0);
        },
        updateProgress: function updateProgress() {
            var el = document.getElementById('progress');
            if (el) {
                el.animate([{ width: "0" }, { width: "100%" }], { duration: this.get('rotation'), easing: "linear" });
            }
        }
    });
});