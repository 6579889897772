define('ocular-ember-plate/helpers/replace-string', ['exports', 'ocularium-frontend-core/helpers/replace-string'], function (exports, _replaceString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _replaceString.default;
    }
  });
  Object.defineProperty(exports, 'replaceString', {
    enumerable: true,
    get: function () {
      return _replaceString.replaceString;
    }
  });
});