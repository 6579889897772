define('ocular-ember-plate/models/modules/interactivemap/interactivemap-settings', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        additionalSettings: _emberData.default.attr(),
        description: _emberData.default.attr(),
        categories: _emberData.default.attr(),
        hasIntroduction: _emberData.default.attr(),
        maps: _emberData.default.attr(),
        moduleName: _emberData.default.attr(),
        title: _emberData.default.attr()
    });
});