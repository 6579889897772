define('ocular-ember-plate/services/scroll-activity', ['exports', 'ember-user-activity/services/scroll-activity'], function (exports, _scrollActivity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _scrollActivity.default;
    }
  });
});