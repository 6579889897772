define('ocular-ember-plate/pods/components/grid-content-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    bills: null,
    selectedBill: null,
    isBillsCollection: null,
    activeTag: null,
    numberOfBills: null,

    isMobile: false,
    isTablet: false,
    isDesktop: false,

    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.calculateScreenWidth();
      Ember.$(window).on('resize', this.resizeHandler.bind(this));
    },


    tagsChanged: Ember.observer('tags.[]', 'activeTag', 'intl.locale.[0]', function () {
      var _this = this;

      clearTimeout(this.get('resizeTimeout'));
      var tags = [].concat(_toConsumableArray(document.getElementsByClassName('billsInformation__tags__tag')));
      for (var i = tags.length - 1; i > 0; i--) {
        tags[i].classList.remove('hidden');
      }
      this.set('resizeTimeout', setTimeout(function () {
        _this.calculateTags();
      }, 0));
    }),

    didInsertElement: function didInsertElement() {
      this.calculateTags();
    },
    resizeHandler: function resizeHandler() {
      var _this2 = this;

      clearTimeout(this.get('resizeTimeout'));
      var tags = [].concat(_toConsumableArray(document.getElementsByClassName('billsInformation__tags__tag')));
      for (var i = tags.length - 1; i > 0; i--) {
        tags[i].classList.remove('hidden');
      }

      this.set('resizeTimeout', setTimeout(function () {

        _this2.calculateTags();
      }, 100));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize");
      clearTimeout(this.get('flipTimeout'));
    },
    calculateTags: function calculateTags() {
      this.set('overflow', false);

      var info = document.getElementById("infoContainer");
      var tags = [].concat(_toConsumableArray(document.getElementsByClassName('billsInformation__tags__tag')));

      var counter = null;
      for (var i = tags.length - 1; i > 0; i--) {
        if (tags[i].offsetTop + tags[i].offsetHeight >= info.offsetHeight) {
          tags[i].classList.add('hidden');
          counter = i;
        }
      }
      if (counter) {
        tags[counter - 1].classList.add('hidden');
        this.set('overflow', true);
      } else {
        this.set('overflow', false);
      }
    },
    calculateScreenWidth: function calculateScreenWidth() {
      var screenWidth = document.documentElement.clientWidth;

      if (screenWidth < 720) {
        this.set('isMobile', true);
        this.set('isTablet', false);
        this.set('isDesktop', false);
      } else if (screenWidth >= 720 && screenWidth < 1400) {
        this.set('isMobile', false);
        this.set('isTablet', true);
        this.set('isDesktop', false);
      } else if (screenWidth >= 1400) {
        this.set('isMobile', false);
        this.set('isTablet', false);
        this.set('isDesktop', true);
      }
    },


    actions: {
      onClickFlipBills: function onClickFlipBills() {
        var _this3 = this;

        this.set('isFlipping', true);
        this.set('flipTimeout', setTimeout(function () {
          _this3.set('isFlipping', false);
        }, 800));
        if (Ember.$('.billsGrid__bill').hasClass('flip')) {
          Ember.$('.billsGrid__bill').removeClass('flip');
        } else {
          Ember.$('.billsGrid__bill').addClass('flip');
        }
      },
      onClickBill: function onClickBill(bill) {
        this.set('selectedBill', bill);
        this.onSelectBill(bill);
      },
      onClickTag: function onClickTag(tag) {
        this.onSelectTag(tag);
        this.set('overflowActive', false);
      },
      onClickTagOverflow: function onClickTagOverflow() {
        this.set('overflowActive', true);
      },
      onClickBack: function onClickBack() {
        this.set('overflowActive', false);
      }
    }
  });
});